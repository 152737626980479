import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material-module'
import { AuditListComponent } from './audit-list/audit-list.component';
import { AuditEditComponent } from './audit-edit/audit-edit.component';
import { AuditService } from './audit-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';

@NgModule({
	declarations: [
		AuditListComponent
		, AuditEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		DialogsModule,
		RouterModule.forChild([
			{ path: 'audits', component: AuditListComponent },
			{ path: 'audit-details/:Id', component: AuditEditComponent },
		])
	],
	providers: [
		AuditService
	]
})
export class AuditModule { }
