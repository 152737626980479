
<div class='card card-primary animaPlaceholder'>
	<div class='card-heading'>
	<div class='row'>
		<div class='col-lg-6 col-md-7 col-xs-12 options-bar'>
			{{pageTitle}}
		</div>
		<div class='col-lg-2 col-md-1 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' [routerLink]="['/company-details/0']" [disabled]="progressStatusCompanyList||progressStatusLst||progressStatusPriorityLst">Add New Company</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-6 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full'(click)='showHideSearchClick()' [disabled]="progressStatusCompanyList||progressStatusLst||progressStatusPriorityLst">{{SearchText}}</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='toExcel()' [disabled]="progressStatusCompanyList||progressStatusLst||progressStatusPriorityLst">To Excel</button>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Company Name</label>
				<input type='text' class='form-control block-element' placeholder=' Company Name' [(ngModel)]='CompanyName' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> City</label>
				<input type='text' class='form-control block-element' placeholder=' City' [(ngModel)]='City' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Province</label>
				<input type='text' class='form-control block-element' placeholder=' Province' [(ngModel)]='Province' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Status</label>
				<mat-select class="form-control" [(ngModel)]='Status'>
					<mat-option [value]="">Select Status</mat-option>
					@for (item of statusList; track item) {
					  <mat-option [value]="item.Value">{{item.Value}}</mat-option>
					}
				</mat-select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Priority</label>
				<mat-select class="form-control" [formControl]="Priorities" multiple>
				  @for (item of priorityList; track item) {
					<mat-option [value]="item.Value">{{item.Value==''?'(empty)':item.Value}}</mat-option>
				  }
				</mat-select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Hide Next Contact Greater than</label>
				<input class='form-control' type='date' [(ngModel)]='HideDateGT' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-actions'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-light' style='min-width: 80px;' [routerLink]="['/company-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='CompanyName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Company Name</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.CompanyName}}</td>
	</ng-container>
	<ng-container matColumnDef='Status'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Status</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Status}}</td>
	</ng-container>
	<ng-container matColumnDef='Priority'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Priority</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Priority}}</td>
	</ng-container>
	<ng-container matColumnDef='LastContactDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-200'> Last Contact Date</th>
		<td class='col-200' mat-cell *matCellDef='let e'>
			<a mat-button matTooltip="See last contact details" *ngIf="e.LastContactDate" (click)="GetLastEngagementByCompanyId(e.Id)"><mat-icon>remove_red_eye</mat-icon>{{e.LastContactDate}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='NextContact'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-200'> Next Contact Date</th>
		<td mat-cell *matCellDef='let e' class='col-200' >{{e.NextContact}}</td>
	</ng-container>
	<ng-container matColumnDef='Address'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Address</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Address}}</td>
	</ng-container>
	<ng-container matColumnDef='City'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> City</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.City}}</td>
	</ng-container>
	<ng-container matColumnDef='Province'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Province</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Province}}</td>
	</ng-container>
	<ng-container matColumnDef='Country'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Country</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Country}}</td>
	</ng-container>
	<ng-container matColumnDef='PostalCode'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Postal Code</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.PostalCode}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-300'> Notes</th>
		<td mat-cell *matCellDef='let e' class='col-300' >{{e.Notes}}</td>
	</ng-container>
	<ng-container matColumnDef='Website'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Website</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Website}}</td>
	</ng-container>
	<ng-container matColumnDef='Email'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Email</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Email}}</td>
	</ng-container>
	<ng-container matColumnDef='Phone'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Phone</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Phone}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>
<div>
<app-loading *ngIf="progressStatusCompanyList||progressStatusLst||progressStatusPriorityLst"></app-loading> 
</div>


