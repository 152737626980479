import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomer } from '../customer-model/customer';
import { Subscription } from 'rxjs/Subscription';
import { CustomerService } from '../customer-service';
import { ICustomers } from '../Customer-model/Customers';
import { ICustomerSearch } from '../Customer-model/CustomerSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './customer-edit.component.html',
	styleUrls: ['customer-edit.component.css']
})

export class CustomerEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Customers';
	errorMessage: string;
	customerForm: FormGroup;
	customer: ICustomer;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public CustomerSearch: ICustomerSearch;
	public ListData: ICustomers;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
    public inCompanyId:number;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private customerService: CustomerService,
		) {
	}

	ngOnInit(): void {
		this.customerForm = this.fb.group({
			Id: '',
			CompanyId: '',
			FirstName: ['',[Validators.maxLength(250)]],
			LastName: ['',[Validators.maxLength(250)]],
			Email: ['',[Validators.maxLength(250)]],
			PhoneNumber: ['',[Validators.maxLength(250)]],
			Title: ['',[Validators.maxLength(100)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.inCompanyId =  +params['Id2']; 
				this.getCustomer(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.customerService.getRecordSet(this.CustomerSearch)
			.subscribe((obj: ICustomers) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICustomers){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.customer.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveCustomer(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.customerForm.valid) {
			let obj = Object.assign({ }, this.customer, this.customerForm.value);
			obj.CompanyId=this.inCompanyId;
			this.customerService.saveCustomer(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.customerForm.reset();
			this.router.navigate(['/company-details',this.inCompanyId]);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.customer.Id === 0) {
			this.router.navigate(['/customer-details', data.Id,this.inCompanyId]);
		} 
		else if (this.customer.Id != 0){
			this.getCustomer(this.customer.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.customer.Id)
				record = count;
		});

		this.getCustomer(this.ListData.data[record].Id)
	}

	getCustomer(id: number): void {
		this.customerService.getCustomer(id)
			.subscribe((obj: ICustomer) => this.onCustomerRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onCustomerRetrieved(customer: ICustomer): void {
		if (this.customerForm) {
			this.customerForm.reset();
		}
		this.customer = customer;
		if (this.customer.Id === 0) {
			this.page = 'Add Customers';
		} else {
			this.page = 'Edit Customers';
		}

		this.customerForm.patchValue({
			Id: this.customer.Id,
			CompanyId: this.customer.CompanyId,
			FirstName: this.customer.FirstName,
			LastName: this.customer.LastName,
			Email: this.customer.Email,
			PhoneNumber: this.customer.PhoneNumber,
			Title: this.customer.Title,
			CreatedDate: this.customer.CreatedDate,
			CreatedBy: this.customer.CreatedBy,
			UpdatedDate: this.customer.UpdatedDate,
			UpdatedBy: this.customer.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteCustomer(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.customer.CompanyId}?`)) {
			this.customerService.deleteCustomer(this.customer.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/company-details',this.inCompanyId]);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Customers List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.CustomerSearch = this.customerService.getFreshSearch();
		this.UserSearches.forEach(i => {
			// if (i.Item == 'CompanyId')
			// 	this.CustomerSearch.CompanyId = i.Value;

			if (i.Item == 'FirstName')
				this.CustomerSearch.FirstName = i.Value;

			if (i.Item == 'LastName')
				this.CustomerSearch.LastName = i.Value;

			if (i.Item == 'Email')
				this.CustomerSearch.Email = i.Value;

			if (i.Item == 'PhoneNumber')
				this.CustomerSearch.PhoneNumber = i.Value;

			if (i.Item == 'orderByString')
				this.CustomerSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.CustomerSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
