import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IEquipment} from './equipment-model/equipment';
import { IEquipments } from './equipment-model/equipments';
import { IEquipmentSearch } from './equipment-model/equipmentsearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class EquipmentService {
	private baseUrl;
	public pageTitle = 'Equipments List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Equipments';
	}

	getEquipment(id: number):  Observable<IEquipment> {
		if (id === 0) {
			return of(this.initializeEquipment());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IEquipment>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveEquipment(equipment: IEquipment): Observable<any> {
		console.log(equipment);
		if (equipment.Id === 0) {
			return this.createEquipment(equipment);
		}
		return this.updateEquipment(equipment);
	}

	private createEquipment(equipment: IEquipment): Observable<any> {
		equipment.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/equipmentcreate', equipment)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateEquipment(equipment: IEquipment): Observable<IEquipment> {
		return this.authHttp.put<IEquipment>(this.baseUrl, equipment)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteEquipment(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeEquipment(): IEquipment {
		return {
			Id: 0,
			Name: '',
			PrinterType: '',
			IP: '',
			BranchId: '',
			ZPL: '',
			PLCChannel:0,
			Port: 0,
			MacAddress:'',
			FileName:'',
			Email:'',
			SendAlert:0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',

		} as IEquipment;
	}

	public getRecordSet(search: IEquipmentSearch): Observable<IEquipments> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PrinterType', search.PrinterType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('IP', search.IP, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ZPL', search.ZPL, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PLCChannel', search.PLCChannel, this.pageTitle));	
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
		};

		return this.authHttp.get<IEquipments>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IEquipments>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IEquipmentSearch { 
		let search: IEquipmentSearch = {
			Id: 1,
			Name: '',
			PrinterType: '',
			IP: '',
			BranchId: '',
			ZPL:'',
			PLCChannel:'',
			Port: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	
	public toExcel(search: IEquipmentSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	getEquipmentsData(name,branchid,type,macaddress):  Observable<any[]> {
	
		const url = `${this.baseUrl}/getequipmentsdata?name=${name}&branchid=${branchid}&printertype=${type}&macaddress=${macaddress}`;
		return this.authHttp.get<any[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllEquipmentsData(branchid):  Observable<any[]> {
		const url = `${this.baseUrl}/getallequipmentsdata?branchid=${branchid}`;
		return this.authHttp.get<any[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllJobEquipmentsData(branchid):  Observable<any[]> {
		const url = `${this.baseUrl}/getalljobequipmentsdata?branchid=${branchid}`;
		return this.authHttp.get<any[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadimage`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}

	getAllEquipments():  Observable<IEquipment[]> {
		return this.authHttp.get<IEquipment[]>(this.baseUrl)
			.pipe(retry(1),
			catchError(this.processError));
	};

}