<div class="row">
    <div class="col-md-12">
       

        <div *ngIf="angularDateVersion!=webApiVersion">
            <div class="alert alert-warning">Web Page version: {{angularDateVersion}}, Server version: {{webApiVersion}}, refresh your browser to get the latest
            </div>
        </div>
        <div *ngIf="angularDateVersion==webApiVersion">
           <div class="alert alert-success">Web Page version: {{angularDateVersion}}
            </div>
        </div>

    </div>
</div>