import { Component, OnInit } from '@angular/core';
import { IRole } from '../role-model/role';
import { RoleService } from '../role-service/role.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
@Component({
	templateUrl: './role-list.component.html'
})
export class RolesListComponent implements OnInit {
	pageTitle = 'Roles List';
	errorMessage = '';
	roles!: IRole[];
	public recordTotal: number = 0;
	displayedColumns: string[] = ['Id', 'Name'];

	public dataSource: any;
	constructor(private _roleService: RoleService
		,private router:Router) {}

	ngOnInit(): void {
		this.dataSource = null; 
		this._roleService
			.getRoles()
			.subscribe(
				roles => {
					this.roles = roles;
					this.dataSource = new MatTableDataSource(roles);
					this.recordTotal = roles.length;
				},
				error => (this.errorMessage = <any>error)
			);
	}
	
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/roles`])
		);
		window.open(url, '_blank');
	}
}
