import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPart } from '../part-model/part';
import { Subscription } from 'rxjs/Subscription';
import { PartService } from '../part-service';
import { IParts } from '../Part-model/Parts';
import { IPartSearch } from '../Part-model/PartSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { IMaterial } from '../../materials/Material-model/Material';
import { IWorkarea } from '../../workareas/Workarea-model/Workarea';
import { MaterialService } from '../../materials/material-service';
import { WorkareaService } from '../../workareas/workarea-service';
import { PartFileDto } from '../part-model/partFileDto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';

@Component({
	templateUrl: './part-edit.component.html',
	styleUrls: ['part-edit.component.css']
})

export class PartEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	public page = 'Edit Parts';
	errorMessage: string;
	partForm: FormGroup;
	part: IPart;

	materials: IMaterial[];
	workareas: IWorkarea[];

	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public PartSearch: IPartSearch;
	public ListData: IParts;
	public showNextRecordButton: boolean = true;
	public progressStatus=true;

	ShapesList: string[] = ['Round', 'Round Shaft', 'Square', 'Rectangle'];
	shapeSelected = '';

	public filesForm: FormGroup
	fileFormSubmitted: boolean = false;
	fileToEdit: PartFileDto;
	public dataSource: any;
	displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];

	imgSquare: string = './assets/square_template.png';
	imgRound: string = './assets/round_template.png';
	imgRoundShaft: string = './assets/round_template.png';
	imgRectangle: string = './assets/rectangle_template.png'
	imgSpacingTemplate: string = './assets/spacing_template.png'

	partFileMainImage: PartFileDto;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private partService: PartService
		, private materialService: MaterialService
		, private workareaService: WorkareaService
		, public snackBar: MatSnackBar
		, private applicationSettings:ApplicationSettings
		, public dialog: MatDialog
		) {
	}

	ngOnInit(): void {
		this.partForm = this.fb.group({
			Id: '',
			MaterialId: ['',[Validators.min(1)]],
			WorkAreaId: ['',[Validators.min(1)]],
			PartNumber: ['',[Validators.required, Validators.maxLength(255)]],
			Description: ['',[Validators.maxLength(4000)]],
			LightsOut: '',
			Intervention: '',
			InterventionQuantity: ['',[Validators.required, Validators.min(1)]],
			InterventionRunTime: ['',[Validators.required, Validators.min(1)]],
			
			PartShape: '',
			Radius: '',
			Length: '',
			Width: '',
			Height: '',
			FirstPartX: '',
			FirstPartY: '',
			SpacingX: '',
			SpacingY: '',
			Rows: '',
			Columns: '',

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.filesForm = this.fb.group({
			fileUpload:['',Validators.required],
			fileDescription:[''],
			sortOrder:[''],
			includeReport:[''],
		})

		this.workareaService.getAllWorkareas().subscribe(data => {
			this.workareas = data;
		});
		this.materialService.getAllMaterials().subscribe(data => {
			this.materials = data;
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getPart(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.partService.getRecordSet(this.PartSearch)
			.subscribe((obj: IParts) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
				this.progressStatus = false;
		});
	}

	private onSelectedRecordSetReceived(obj: IParts){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.part.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length){
			this.showNextRecordButton = true;
		}
		else{
			this.showNextRecordButton = false;
		}
		this.progressStatus = false;

	}

	savePart(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.partForm.valid) {
			const obj = Object.assign({ }, this.part, this.partForm.value);
			this.partService.savePart(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.partForm.reset();
			this.router.navigate(['/parts']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/part-details', 0]);
			this.partForm.reset();
			let obj = this.partService.initializePart();
			this.onPartRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.part.Id === 0) {
			this.router.navigate(['/part-details', data.Id]);
		} 
		else if (this.part.Id != 0){
			this.getPart(this.part.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.part.Id)
				record = count;
		});
		this.router.navigate(['/part-details', this.ListData.data[record].Id]);
	}

	getPart(id: number): void {
		this.partService.getPart(id)
			.subscribe((obj: IPart) => this.onPartRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		this.progressStatus = false;
		// this.spinner.stop();
		});
	}

	onPartRetrieved(part: IPart): void {
		if (this.partForm) {
			this.partForm.reset();
		}
		this.part = part;
		if (this.part.Id === 0) {
			this.page = 'Add Parts';
		} else {
			this.page = 'Edit Parts';
		}

		this.partForm.patchValue({
			Id: this.part.Id,
			MaterialId: this.part.MaterialId,
			WorkAreaId: this.part.WorkAreaId,
			PartNumber: this.part.PartNumber,
			Description: this.part.Description,
			LightsOut: this.part.LightsOut,
			Intervention: this.part.Intervention,
			InterventionQuantity: this.part.InterventionQuantity,
			InterventionRunTime: this.part.InterventionRunTime,
			PartShape: this.part.PartShape,
			Radius: this.part.Radius,
			Length: this.part.Length,
			Width: this.part.Width,
			Height: this.part.Height,
			FirstPartX: this.part.FirstPartX,
			FirstPartY: this.part.FirstPartY,
			SpacingX: this.part.SpacingX,
			SpacingY: this.part.SpacingY,
			Rows: this.part.Rows,
			Columns: this.part.Columns,
			CreatedDate: this.part.CreatedDate,
			CreatedBy: this.part.CreatedBy,
			UpdatedDate: this.part.UpdatedDate,
			UpdatedBy: this.part.UpdatedBy,
		});

		this.shapeSelected = this.part.PartShape
		this.getFilePartData();
		this.getUserSearchData();
		this.getMainImagePart();
	}

	ngOnDestroy(): void {
	}

	deletePart(): void {
			this.partService.deletePart(this.part.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
	}

	cancel(): void {
		this.router.navigate(['/parts']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Parts List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) =>{this.errorMessage = < any > error;this.progressStatus = false;});
	}

	setUserFilters(): void {
		this.PartSearch = this.partService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'MaterialId')
				this.PartSearch.MaterialId = i.Value;

			if (i.Item == 'WorkAreaId')
				this.PartSearch.WorkAreaId = i.Value;

			if (i.Item == 'PartNumber')
				this.PartSearch.PartNumber = i.Value;

			if (i.Item == 'Description')
				this.PartSearch.Description = i.Value;

			if (i.Item == 'orderByString')
				this.PartSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.PartSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}


	shapeValueChanged(type: string): void {
			this.shapeSelected = type;
	}

	onFileSelected(event) {
		if (event.target.files.length > 0) {
		 this.filesForm.patchValue({
		 fileUpload: event.target.files [0],
		 })
		 }
	}
	onSaveFile(){
		this.fileFormSubmitted = true;

		if (this.filesForm.invalid)
		{
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.part.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
			formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.partService.updateFilePart(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFilePartData();
			});
		}
		else
		{
			this.partService.createFilePart(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFilePartData();
				this.openSnackBar('File changes have been successfully saved','Ok',5000);
			});
				
			}
		}
		
	editFileData(file){
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			fileDescription: this.fileToEdit.FileDescription,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
		});
	}

	clearForm() {
		this.filesForm.reset();
			this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${ this.applicationSettings.retrieveUrl()}${ row.FilePath}${ row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.partService.deleteFilePart(id).subscribe(file => {this.getFilePartData();this.openSnackBar('File has been successfully deleted','Ok',5000);})
	}


	//This gets the file grid
	getFilePartData(){
		if (this.part)
		{
			if (this.part.Id > 0)
			{
				this.partService.getAllFilesPart(this.part.Id).subscribe(response => {
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
				});
			}
		}
	}

	getMainImagePart(){
		if (this.part)
		{
			if (this.part.Id > 0)
			{
				this.partService.getMainImagePart(this.part.Id).subscribe(response => {
					this.partFileMainImage = response;
				});
			}
		}
	}
	
	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	showDialog(type:string,id: string): void {
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this ${type.toLowerCase()}?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				if(type=='Part'){
				this.deletePart();
				}
				if(type=='File'){
					this.deleteFileData(id);
				}
			} else {
			  return;
			}
		  });
	  }
}
