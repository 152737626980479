<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-lg-10 col-md-8 col-xs-6 options-bar'>
				{{pageTitle}}
			</div>
			<div class='col-lg-2 col-md-4 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [routerLink]="['/create-role']"><mat-icon>add</mat-icon>Add Role</button>
			</div>
		</div>
	</div>
</div>
		<div class='table-container'>
			<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
				<ng-container matColumnDef='Id'>
					<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-actions'>Action</th>
					<td class="td-center" mat-cell *matCellDef='let e'>
						<button [routerLink]="['/edit-role', e.Id]" mat-icon-button
						matTooltip="Edit this role"><mat-icon>edit</mat-icon></button>
					</td>
				</ng-container>
				<ng-container matColumnDef='Name'>
					<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-800'> Name</th>
					<td mat-cell *matCellDef='let e'>{{e.Name}}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
				<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
			</table>
		</div>
		<mat-paginator [length]='recordTotal'></mat-paginator>
	