
<div class='panel panel-primary animaPlaceholder' *ngIf = 'workarea'>
	<div class='panel-heading'>
		<span *ngIf = 'workarea' >{{ page }} :{{ workarea.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='workareaForm'>
			<fieldset>
				<div class='panel-body'>
					<div class="row">
						<div class="col-md-6">
							<div class='form-group'>
								<label class='col-md-2 control-label'> Branch</label>
								<div class='col-md-10'>
									<ng-select
										formControlName='BranchId'
										placeholder="Select branch...">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let branch of branches" [value]="branch.Id">
											{{branch.BranchName}}
										</ng-option>
									</ng-select>
									<span *ngIf = "workareaForm.controls['BranchId'].invalid  && workareaForm.controls['BranchId'].errors.min" class="text-danger">
											Must select a branch.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Name' />
									<span *ngIf = "workareaForm.controls['Name'].invalid  && workareaForm.controls['Name'].errors.required" class="text-danger">
										Must have a name.
									</span>
									<span *ngIf = "workareaForm.controls['Name'].invalid  && workareaForm.controls['Name'].errors.maxlength" class="text-danger">
											Only 50 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Nick Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='NickName' />
									<span *ngIf = "workareaForm.controls['NickName'].invalid  && workareaForm.controls['NickName'].errors.maxlength" class="text-danger">
											Only 50 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Color</label>
								<div class='col-md-10'>
									<ejs-input ejs-colorpicker type="color" id="element" mode="Palette" formControlName="Color"></ejs-input>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Unlimited Jobs</label>
								<div class='col-md-10 pull-left'>
									<mat-checkbox formControlName='UnlimitedJobs' color='primary'></mat-checkbox>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Lights Out</label>
								<div class='col-md-10 pull-left'>
									<mat-checkbox formControlName='LightsOut' color='primary'></mat-checkbox>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Ignore</label>
								<div class='col-md-10 pull-left'>
									<mat-checkbox formControlName='Ignore' color='primary'></mat-checkbox>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class='form-group'>
								<label class='col-md-2 control-label'> Printer Type</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='PrinterType' />
									<span *ngIf = "workareaForm.controls['PrinterType'].invalid  && workareaForm.controls['PrinterType'].errors.maxlength" class="text-danger">
											Only 50 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> IP</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='IP' />
									<span *ngIf = "workareaForm.controls['IP'].invalid  && workareaForm.controls['IP'].errors.maxlength" class="text-danger">
											Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> ZPL</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='ZPL' />
									<span *ngIf = "workareaForm.controls['ZPL'].invalid  && workareaForm.controls['ZPL'].errors.maxlength" class="text-danger">
											Only 4000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>  PLC Channel</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='PLCChannel' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Channel</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='Channel' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Port</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='port' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Mac Address</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='MacAddress' />
									<span *ngIf = "workareaForm.controls['MacAddress'].invalid  && workareaForm.controls['MacAddress'].errors.maxlength" class="text-danger">
											Only 50 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> File Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='FileName' />
									<span *ngIf = "workareaForm.controls['FileName'].invalid  && workareaForm.controls['FileName'].errors.maxlength" class="text-danger">
											Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Email</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Email' />
									<span *ngIf = "workareaForm.controls['Email'].invalid  && workareaForm.controls['Email'].errors.maxlength" class="text-danger">
											Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Send Alert</label>
								<div class='col-md-10 pull-left'>
									<mat-checkbox formControlName='SendAlert' color='primary'></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</form>
		<div class='row' style='padding: 15px;'>
			<div class= 'col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml'
					[disabled]='buttonPushed'
						(click)='saveWorkarea(false, false,false)'
							style='width: 110px; margin-right:10px'
							[disabled]='!workareaForm.valid || progressStatus'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
						</button>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
							(click)='saveWorkarea(true, false,false)'
							style='width: 150px; margin-right:10px'
							[disabled]='!workareaForm.valid || progressStatus'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
						</button>
						<button *ngIf='showNextRecordButton && workarea.Id ' class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
							(click)='saveWorkarea(false, true,false)'
							style='width: 190px; margin-right:10px'
							[disabled]='!workareaForm.valid || progressStatus'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
						</button>
						<button  class='btn btn-primary btn-edit-sml' *ngIf="!workarea.Id"  
							[disabled]='buttonPushed'
							(click)='saveWorkarea(false, false,true)'
							style ='width: 200px; margin-right:10px'
							[disabled]='!workareaForm.valid || progressStatus'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
						</button>
				</span>
				<span  class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='cancel()'
						style='width: 150px; margin-right:10px'
						[disabled]='!workareaForm.valid || progressStatus'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' *ngIf="workarea.Id" 
						[disabled]='buttonPushed'
						(click)='deleteWorkarea()'
						style='width: 150px; margin-right:10px'
						[disabled]='!workareaForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='workarea'>{{workarea.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='workarea'>{{workarea.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>