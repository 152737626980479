import { NgModule, APP_INITIALIZER } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { NavigationComponent } from './shared/navigation/navigation.component';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ApplicationSettings } from './shared/application-settings';
import { SpinnerService } from './shared/spinner/spinner.service';

import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './shared/spinner/spinner.component';

import { ConfigurationService } from './shared/configuration.service';
import { DatePipe } from '@angular/common';

// Public Sites
import { AboutUsComponent } from './about-us/about-us.component';
import { FanucComponent } from './fanuc/fanuc.component';
import { ContactComponent } from './contact/contact.component';
import { PlatformComponent } from './platform/platform.component';
import { FooterComponent } from './footer/footer.component';

import { LoginComponent } from './admin/login/login.component';
import { AuthorizationModule } from './admin/auth/authorization.module';

import { AdminModule } from './admin/admin.module'; 
import { WorktaskModule } from './worktasks/worktask-module';

import { DailyLogModule } from './dailylog/dailylog-module';

import { MaterialModule } from './material-module'; 

import { MAT_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { GridtestComponent } from './gridtest/gridtest.component';

import { GreenModule } from './greens/green-module';
import { HelpModule } from './helps/help-module';
import { LookuplistModule } from './lookuplists/lookuplist-module';
import { VideoModule } from './videos/video-module';
import { ContactService } from './contact/contact-service';
import { CompanyModule } from './companys/company-module';
import { CustomerModule } from './customers/customer-module';
import { CustomercontactModule } from './customercontacts/customercontact-module';

import { MachineHMIModule } from './machine-hmi/machine-hmi-module';
import { PartModule } from './parts/part-module';
import { EquipmentModule } from './equipments/equipment-module';
import { MaterialflexModule } from './materials/materialflex-module';
import { WorkareaModule } from './workareas/workarea-module';

//Header version component
import { HeaderVersionComponent } from './shared/header-version/header-version.component';
import { HeaderVersionModule } from './shared/header-version/header-version-module';
import {MatInputModule as MatInputModule} from '@angular/material/input';
import { ServicesComponent } from './services/services.component';
import { DialogsModule } from './shared/dialogs/dialogs.module';
import { JobModule } from './jobs/job-module';
import { BranchlabelprinterModule } from './branchlabelprinters/branchlabelprinter-module';
import { TaskModule } from './tasks/task-module';

import {DragDropModule} from '@angular/cdk/drag-drop';

export function start(config: ApplicationSettings) {
	function result() {
		return config.getUrl();
	}
	return result;
}

@NgModule({
	declarations: [
		ServicesComponent,
		GridtestComponent,
		LoginComponent,
    	AboutUsComponent,
		AppComponent,
		WelcomeComponent,
		NavigationComponent,
		SpinnerComponent,
		FanucComponent,
		ContactComponent,
		PlatformComponent,
		FooterComponent,
		HeaderVersionComponent
	],
	imports: [
		YouTubePlayerModule,
		WorkareaModule,
		MaterialflexModule,
		EquipmentModule,
		PartModule,
		MachineHMIModule,
		HeaderVersionModule,
		CustomercontactModule,
		CustomerModule,
		CompanyModule,
		VideoModule,
		LookuplistModule,
		HelpModule,
		GreenModule,
		MaterialModule,
		DailyLogModule,
		WorktaskModule,
		AdminModule,
		HttpClientModule,
		AuthorizationModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		DialogsModule,
		JobModule,
		BranchlabelprinterModule,
		TaskModule,
		DragDropModule,
		RouterModule.forRoot([
	{ path: 'services', component: ServicesComponent },
    { path: 'platforms', component: PlatformComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'fanuc', component: FanucComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'login', component: LoginComponent },
	{ path: 'gridtest', component: GridtestComponent },
    { path: '', component: WelcomeComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
], {}) 
	],
	providers: [
		ContactService,
		DatePipe,
		ApplicationSettings,
		SpinnerService,
		ConfigurationService,
		{
			provide: APP_INITIALIZER,
			useFactory: start,
			deps: [ApplicationSettings],
			multi: true
		},
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
