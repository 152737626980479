import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ICompany} from './Company-model/Company';
import { ICompanys } from './Company-model/Companys';
import { ICompanySearch } from './Company-model/CompanySearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { ICompanyEngagements } from './company-model/companyEngagements';
import { ICompanyEngagement } from './company-model/companyEngagement';

@Injectable()
export class CompanyService {
	private baseUrl;
	public pageTitle = 'Companies List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Company';
	}

	getAllCompanies():  Observable<any> {
		return this.authHttp.get<ICompany[]>(this.baseUrl + '/allcompanies')
			.pipe(retry(1),
			catchError(this.processError));
	};

	getCompany(id: number):  Observable<ICompany> {
		if (id === 0) {
			return of(this.initializeCompany());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ICompany>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveCompany(company: ICompany): Observable<any> {
		console.log(company);
		if (company.Id === 0) {
			return this.createCompany(company);
		}
		return this.updateCompany(company);
	}

	private createCompany(company: ICompany): Observable<any> {
		company.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/companycreate', company)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCompany(company: ICompany): Observable<ICompany> {
		return this.authHttp.put<ICompany>(this.baseUrl, company)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCompany(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCompany(): ICompany {
		return {
			Id: 0,
			CompanyName: '',
			Address: '',
			City: '',
			Province: '',
			Country: '',
			PostalCode: '',
			Notes: '',
			Website: '',
			Email: '',
			Phone: '',
			LinkedIn:'',
			Instagram:'',
			Facebook: '',
			Status: '',
			Priority:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICompany;
	}

	public getRecordSet(search: ICompanySearch): Observable<ICompanys> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('CompanyName', search.CompanyName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Address', search.Address, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('City', search.City, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Province', search.Province, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Country', search.Country, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PostalCode', search.PostalCode, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Website', search.Website, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Email', search.Email, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Phone', search.Phone, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Status', search.Status, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Priority', search.Priority, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('HideDateGT', search.HideDateGT, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('CompanyName', search.CompanyName ? search.CompanyName : '')
			.set('Address', search.Address ? search.Address : '')
			.set('City', search.City ? search.City : '')
			.set('Province', search.Province ? search.Province : '')
			.set('Country', search.Country ? search.Country : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Website', search.Website ? search.Website : '')
			.set('Email', search.Email ? search.Email : '')
			.set('Phone', search.Phone ? search.Phone : '')
			.set('Status', search.Status ? search.Status : '')
			.set('Priority', search.Priority ? search.Priority : '')
			.set('HideDateGT', search.HideDateGT ? search.HideDateGT : '')
			
		};

		return this.authHttp.get<ICompanys>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICompanys>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ICompanySearch { 
		let search: ICompanySearch = {
			Id: 1,
			CompanyName: '',
			Address: '',
			City: '',
			Province: '',
			Country: '',
			PostalCode: '',
			Notes: '',
			Website: '',
			Email: '',
			Phone: '',
			Status: '',
			Priority: '',
			HideDateGT: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ICompanySearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('CompanyName', search.CompanyName ? search.CompanyName : '')
			.set('Address', search.Address ? search.Address : '')
			.set('City', search.City ? search.City : '')
			.set('Province', search.Province ? search.Province : '')
			.set('Country', search.Country ? search.Country : '')
			.set('PostalCode', search.PostalCode ? search.PostalCode : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Website', search.Website ? search.Website : '')
			.set('Email', search.Email ? search.Email : '')
			.set('Phone', search.Phone ? search.Phone : '')
			.set('Status', search.Status ? search.Status : '')
			.set('Priority', search.Priority ? search.Priority : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	updateFileCompany(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<ICompanys>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileCompany(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesCompany(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<ICompanys[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileCompany(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}



	
	GetAllEngagementsByCompany(companyId: number){
		const url=`${this.baseUrl}/GetAllEngagementsByCompany?companyId=${companyId}`;
		return this.authHttp.get<ICompanyEngagements[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	CreateEngagement(companyEngagement: ICompanyEngagement): Observable<any> {
		companyEngagement.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/CreateEngagement', companyEngagement)
			.pipe(retry(1), catchError(this.processError));
	}

	UpdateEngagement(companyEngagement: ICompanyEngagement): Observable<ICompanyEngagement> {
			return this.authHttp.put<ICompanyEngagement>(this.baseUrl+ '/UpdateEngagement', companyEngagement)
				.pipe(retry(1), catchError(this.processError));
	}

	SaveEngagement(companyEngagement: ICompanyEngagement): Observable<any> {
		if (companyEngagement.Id === 0) {
			return this.CreateEngagement(companyEngagement);
		}
		return this.UpdateEngagement(companyEngagement);
	}

	DeleteEngagement(id: number){
		const url=`${this.baseUrl}/DeleteEngagement?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	GetLastEngagementByCompanyId(id: number):  Observable<ICompanyEngagement> {
		const url=`${this.baseUrl}/GetLastByCompanyId?companyId=${id}`;
		return this.authHttp.get<ICompanyEngagement>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}