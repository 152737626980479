import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RolesListComponent } from '../roles/role-list/role-list.component';
import { RoleService } from '../roles/role-service/role.service';
import { RoleEditComponent } from '../roles/role-edit/role-edit.component';
import { RoleCreateComponent } from '../roles/role-create/role-create.component';
import { AuthGuard } from '../auth/auth-guard.service';
import { AuthGuardAdmin } from '../auth/auth-guard-admin.service';
import { MaterialModule } from '../../material-module';
@NgModule({
	declarations: [RolesListComponent, RoleEditComponent, RoleCreateComponent],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		MaterialModule,
		RouterModule.forChild([
			{
				path: 'roles',
				component: RolesListComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'edit-role/:Id',
				component: RoleEditComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			},
			{
				path: 'create-role',
				component: RoleCreateComponent,
				canActivate: [AuthGuard, AuthGuardAdmin]
			}
		])
	],
	providers: [RoleService]
})
export class RoleModule {}
