import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ApplicationSettings } from '../../shared/application-settings';
import { EquipmentService } from '../equipment-service';

@Component({
	templateUrl: './equipment-help.component.html',
	styleUrls: ['./equipment-help.component.css']
})

export class EquipmentHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'BranchLabelPrinters Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor(
		private _appService: EquipmentService) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}