
<div class='panel panel-primary animaPlaceholder' *ngIf = 'lookuplist'>
	<div class='panel-heading'>
		<span *ngIf = 'lookuplist' >{{ page }} : {{ lookuplist.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='lookuplistForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Form</label>
						<div class='col-md-10 form-group-item'>
							<input class='form-control' type='text' formControlName='Form' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Field</label>
						<div class='col-md-10 form-group-item'>
							<input class='form-control' type='text' formControlName='Field' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Value</label>
						<div class='col-md-10 form-group-item'>
							<input class='form-control' type='text' formControlName='Value' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveLookuplist(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!lookuplistForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLookuplist(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!lookuplistForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLookuplist(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!lookuplistForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!lookuplistForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteLookuplist()'
								style='width: 150px; margin-right:10px'
								[disabled]='!lookuplistForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='lookuplist'>{{lookuplist.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='lookuplist'>{{lookuplist.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>