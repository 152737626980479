
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-default btn-sm' [routerLink]="['/workarea-details/0']" style='width:auto;' [disabled]="progressStatus">Add New Work Area</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml text-right'>
		<button class='btn btn-primary btn-sm' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-default btn-sm' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml text-right'>
			<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Branch Id</label>
				<input type='text' class='form-control block-element' placeholder=' Branch Id' [(ngModel)]='BranchId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Name</label>
				<input type='text' class='form-control block-element' placeholder=' Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Nick Name</label>
				<input type='text' class='form-control block-element' placeholder=' Nick Name' [(ngModel)]='NickName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Color</label>
				<input type='text' class='form-control block-element' placeholder=' Color' [(ngModel)]='Color' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Printer Type</label>
				<input type='text' class='form-control block-element' placeholder=' Printer Type' [(ngModel)]='PrinterType' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> I P</label>
				<input type='text' class='form-control block-element' placeholder=' I P' [(ngModel)]='IP' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Z P L</label>
				<input type='text' class='form-control block-element' placeholder=' Z P L' [(ngModel)]='ZPL' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> P L C Channel</label>
				<input type='text' class='form-control block-element' placeholder=' P L C Channel' [(ngModel)]='PLCChannel' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Channel</label>
				<input type='text' class='form-control block-element' placeholder=' Channel' [(ngModel)]='Channel' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'>port</label>
				<input type='text' class='form-control block-element' placeholder='port' [(ngModel)]='port' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Mac Address</label>
				<input type='text' class='form-control block-element' placeholder=' Mac Address' [(ngModel)]='MacAddress' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> File Name</label>
				<input type='text' class='form-control block-element' placeholder=' File Name' [(ngModel)]='FileName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Email</label>
				<input type='text' class='form-control block-element' placeholder=' Email' [(ngModel)]='Email' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Send Alert</label>
				<input type='text' class='form-control block-element' placeholder=' Send Alert' [(ngModel)]='SendAlert' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-default' style='min-width: 80px;' [routerLink]="['/workarea-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='BranchId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Branch Id</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.BranchId}}</td>
	</ng-container>
	<ng-container matColumnDef='Name'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Name</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.Name}}</td>
	</ng-container>
	<ng-container matColumnDef='NickName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Nick Name</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.NickName}}</td>
	</ng-container>
	<ng-container matColumnDef='Color'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Color</th>
		<td mat-cell *matCellDef='let e' class='col6' >
			<div class='color-box' [style.background-color]="e.Color"></div>
		</td>
	</ng-container>
	<ng-container matColumnDef='PrinterType'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Printer Type</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.PrinterType}}</td>
	</ng-container>
	<ng-container matColumnDef='IP'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> I P</th>
		<td mat-cell *matCellDef='let e' class='col8' >{{e.IP}}</td>
	</ng-container>
	<ng-container matColumnDef='ZPL'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Z P L</th>
		<td mat-cell *matCellDef='let e' class='col9' >{{e.ZPL}}</td>
	</ng-container>
	<ng-container matColumnDef='PLCChannel'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> P L C Channel</th>
		<td mat-cell *matCellDef='let e' class='col10' >{{e.PLCChannel}}</td>
	</ng-container>
	<ng-container matColumnDef='Channel'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Channel</th>
		<td mat-cell *matCellDef='let e' class='col11' >{{e.Channel}}</td>
	</ng-container>
	<ng-container matColumnDef='port'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col12'>port</th>
		<td mat-cell *matCellDef='let e' class='col12' >{{e.port}}</td>
	</ng-container>
	<ng-container matColumnDef='MacAddress'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col13'> Mac Address</th>
		<td mat-cell *matCellDef='let e' class='col13' >{{e.MacAddress}}</td>
	</ng-container>
	<ng-container matColumnDef='FileName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col14'> File Name</th>
		<td mat-cell *matCellDef='let e' class='col14' >{{e.FileName}}</td>
	</ng-container>
	<ng-container matColumnDef='Email'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col15'> Email</th>
		<td mat-cell *matCellDef='let e' class='col15' >{{e.Email}}</td>
	</ng-container>
	<ng-container matColumnDef='SendAlert'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col16'> Send Alert</th>
		<td mat-cell *matCellDef='let e' class='col16' >{{e.SendAlert}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>