import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChildren
} from '@angular/core';
import {
	FormBuilder,
	FormControlName,
	FormGroup,
	Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericValidator } from '../../../shared/generic-validator';
import { IRole } from '../role-model/role';
import { RoleService } from '../role-service/role.service';

@Component({
	templateUrl: './role-create.component.html'
})
export class RoleCreateComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

	createRoleForm!: FormGroup;
	pageTitle = 'Create Role';
	errorMessage = '';
	role!: IRole;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator!: GenericValidator;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private roleService: RoleService
	) {
		this.validationMessages = {
			Name: {
				required: 'Name is required.'
			}
		};
	}

	ngOnInit(): void {
		this.createRoleForm = this.fb.group({
			Name: ['', [Validators.required]]
		});

		this.genericValidator = new GenericValidator(this.validationMessages);
	}

	ngAfterViewInit(): void {
		// // Watch for the blur event from any input element on the form.
		// const controlBlurs: Observable<any>[] = this.formInputElements.map(
		// 	(formControl: ElementRef) =>
		// 		Observable.fromEvent(formControl.nativeElement, 'blur')
		// );

		// // Merge the blur event observable with the valueChanges observable
		// Observable.merge(this.createRoleForm.valueChanges, ...controlBlurs)
		// 	.debounceTime(800)
		// 	.subscribe(value => {
		// 		this.displayMessage = this.genericValidator.processMessages(
		// 			this.createRoleForm
		// 		);
		// 	});
	}

	cancel(): void {
		this.router.navigate(['/roles']);
	}

	saveRole(): void {
		if (this.createRoleForm.dirty && this.createRoleForm.valid) {
			const roleCreated = Object.assign(
				{},
				this.role,
				this.createRoleForm.value
			);
			this.roleService
				.saveRole(roleCreated)
				.subscribe(
					() => this.onSaveComplete(),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else if (!this.createRoleForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		this.createRoleForm.reset();
		this.router.navigate(['/roles']);
	}
}
