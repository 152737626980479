import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWorkarea } from '../workarea-model/workarea';
import { Subscription } from 'rxjs/Subscription';
import { WorkareaService } from '../workarea-service';
import { IWorkareas } from '../Workarea-model/Workareas';
import { IWorkareaSearch } from '../Workarea-model/WorkareaSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { BrancheService } from '../../admin/branches/branche-service';
import { IBranche } from '../../admin/branches/Branche-model/Branche';

@Component({
	templateUrl: './workarea-edit.component.html',
	styleUrls: ['workarea-edit.component.css']
})

export class WorkareaEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Work Areas';
	errorMessage: string;
	workareaForm: FormGroup;
	workarea: IWorkarea;

	branches: IBranche[];

	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public WorkareaSearch: IWorkareaSearch;
	public ListData: IWorkareas;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private workareaService: WorkareaService
		, private branchService: BrancheService
		) {
	}

	ngOnInit(): void {
		this.workareaForm = this.fb.group({
			Id: '',
			BranchId: ['', [Validators.min(1)]],
			Name: ['',[Validators.required, Validators.maxLength(50)]],
			NickName: ['',[Validators.maxLength(50)]],
			Color: ['',[Validators.maxLength(50)]],
			UnlimitedJobs: '',
			LightsOut: '',
			Ignore: '',
			PrinterType: ['',[Validators.maxLength(50)]],
			IP: ['',[Validators.maxLength(250)]],
			ZPL: ['',[Validators.maxLength(4000)]],
			PLCChannel: '',
			Channel: '',
			port: '',
			MacAddress: ['',[Validators.maxLength(50)]],
			FileName: ['',[Validators.maxLength(250)]],
			Email: ['',[Validators.maxLength(250)]],
			SendAlert: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.branchService.getAllBranches('').subscribe((data) => {
			this.branches = data;
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getWorkarea(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.workareaService.getRecordSet(this.WorkareaSearch)
			.subscribe((obj: IWorkareas) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWorkareas){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.workarea.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveWorkarea(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.workareaForm.valid) {
			const obj = Object.assign({ }, this.workarea, this.workareaForm.value);
			this.workareaService.saveWorkarea(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.workareaForm.reset();
			this.router.navigate(['/workareas']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/workarea-details', 0]);
			this.workareaForm.reset();
			let obj = this.workareaService.initializeWorkarea();
			this.onWorkareaRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.workarea.Id === 0) {
			this.router.navigate(['/workarea-details', data.Id]);
		} 
		else if (this.workarea.Id != 0){
			this.getWorkarea(this.workarea.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.workarea.Id)
				record = count;
		});

		this.getWorkarea(this.ListData.data[record].Id)
	}

	getWorkarea(id: number): void {
		this.workareaService.getWorkarea(id)
			.subscribe((obj: IWorkarea) => this.onWorkareaRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onWorkareaRetrieved(workarea: IWorkarea): void {
		if (this.workareaForm) {
			this.workareaForm.reset();
		}
		this.workarea = workarea;
		if (this.workarea.Id === 0) {
			this.page = 'Add Work Areas';
		} else {
			this.page = 'Edit Work Areas';
		}

		this.workareaForm.patchValue({
			Id: this.workarea.Id,
			BranchId: this.workarea.BranchId,
			Name: this.workarea.Name,
			NickName: this.workarea.NickName,
			Color: this.workarea.Color,
			PrinterType: this.workarea.PrinterType,
			UnlimitedJobs: this.workarea.UnlimitedJobs,
			LightsOut: this.workarea.LightsOut,
			Ignore: this.workarea.Ignore,
			IP: this.workarea.IP,
			ZPL: this.workarea.ZPL,
			PLCChannel: this.workarea.PLCChannel,
			Channel: this.workarea.Channel,
			port: this.workarea.port,
			MacAddress: this.workarea.MacAddress,
			FileName: this.workarea.FileName,
			Email: this.workarea.Email,
			SendAlert: this.workarea.SendAlert,
			CreatedDate: this.workarea.CreatedDate,
			CreatedBy: this.workarea.CreatedBy,
			UpdatedDate: this.workarea.UpdatedDate,
			UpdatedBy: this.workarea.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteWorkarea(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.workarea.Name}?`)) {
			this.workareaService.deleteWorkarea(this.workarea.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/workareas']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Work Areas List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.WorkareaSearch = this.workareaService.getFreshSearch();
		this.UserSearches.forEach(i => {

			if (i.Item == 'BranchId')
				this.WorkareaSearch.BranchId = i.Value;

			if (i.Item == 'Name')
				this.WorkareaSearch.Name = i.Value;

			if (i.Item == 'NickName')
				this.WorkareaSearch.NickName = i.Value;

			if (i.Item == 'Color')
				this.WorkareaSearch.Color = i.Value;

			if (i.Item == 'PrinterType')
				this.WorkareaSearch.PrinterType = i.Value;

			if (i.Item == 'IP')
				this.WorkareaSearch.IP = i.Value;

			if (i.Item == 'ZPL')
				this.WorkareaSearch.ZPL = i.Value;

			if (i.Item == 'PLCChannel')
				this.WorkareaSearch.PLCChannel = i.Value;

			if (i.Item == 'Channel')
				this.WorkareaSearch.Channel = i.Value;

			if (i.Item == 'port')
				this.WorkareaSearch.port = i.Value;

			if (i.Item == 'MacAddress')
				this.WorkareaSearch.MacAddress = i.Value;

			if (i.Item == 'FileName')
				this.WorkareaSearch.FileName = i.Value;

			if (i.Item == 'Email')
				this.WorkareaSearch.Email = i.Value;

			if (i.Item == 'SendAlert')
				this.WorkareaSearch.SendAlert = i.Value;

			if (i.Item == 'orderByString')
				this.WorkareaSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.WorkareaSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
