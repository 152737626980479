<div class="panel panel-primary animated fadeIn" *ngIf="!spinner.active">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>

	<div class="panel-body">
		<div class='has-error text-danger' *ngIf='errorMessage'>{{ errorMessage }}</div>
		<form class="form-horizontal" novalidate [formGroup]="editUserForm">
			<div>
				Fields marked with * are mandatory
			</div>
			<div class="bottomMargin">
				<span class="text-danger" *ngIf="displayMessage.FirstName">
					{{displayMessage.FirstName}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.LastName">
					{{displayMessage.LastName}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.Email">
					{{displayMessage.Email}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.Password">
					{{displayMessage.Password}} &nbsp;
				</span>
			</div>
			<div class="row">
				<div class="col-md-6">
					<h4> User Info </h4>
					<div class="form-group">
						<label class="col-md-2 control-label required" for="Username"><span class="text-danger">*</span>Username</label>
						<div class="col-md-4 form-group-item" style="display: flex;">
							<!-- Do NOT allow editing once userid has been set -->
							<!-- The backend will throw an exception if a call is made that edits the userid -->
							<!-- Either way please leave this disabled thank you :) -->
							<input class="form-control" id="Username" type="text" placeholder="Username (required)" formControlName="Username" />
							<button class="btn btn-primary" (click)="openNewUserNamePopup()" *ngIf="AllowUsernameChanges">C</button>
						</div>
						<label class="col-md-2 control-label" for="Active">Active</label>
						<div class="col-md-1 form-group-item">
							<input id="Active" type="checkbox" formControlName="Active" />
						</div>
					</div>
					<div class="form-group ">
						<label class="col-md-2 control-label required" for="FirstName"><span class="text-danger">*</span>First name</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="FirstName" type="text" placeholder="First Name (required)" formControlName="FirstName" />
						</div>
						<label class="col-md-2 control-label required" for="LastName"><span class="text-danger">*</span>Last name</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="LastName" type="text" placeholder="Last Name (required)" formControlName="LastName" />
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label required" for="Email"><span class="text-danger">*</span>Email</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Email" type="text" placeholder="Email (required)" formControlName="Email" />
						</div>

						<label class="col-md-2 control-label" for="Region">Region</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Region" type="text" placeholder="Region" formControlName="Region" maxlength="50" />
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="Initials">Initials</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Initials" type="text" placeholder="Initials" formControlName="Initials" maxlength="2" />
						</div>

						<label class="col-md-2 control-label" for="Title">Title</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Title" type="text" placeholder="Title" formControlName="Title" maxlength="50" />
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="Address">Address</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Address" type="text" placeholder="Address" formControlName="Address" maxlength="50" />
						</div>

						<label class="col-md-2 control-label" for="City">City</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="City" type="text" placeholder="City" formControlName="City" maxlength="50" />
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="Notes">Notes</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Notes" type="text" placeholder="Notes" formControlName="Notes" />
						</div>

						<label class="col-md-2 control-label" for="PostalCode">Postal Code</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="PostalCode" type="text" placeholder="Postal Code" formControlName="PostalCode" maxlength="10"
							/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="BirthDate">Birth Date</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="BirthDate" type="date" formControlName="BirthDate" />
						</div>

						<label class="col-md-2 control-label" for="HomePhone">Home Phone</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="HomePhone" type="text" placeholder="Home Phone" formControlName="HomePhone" maxlength="22"
							/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="HireDate">Hire Date</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="HireDate" type="date" formControlName="HireDate" />
						</div>

						<label class="col-md-2 control-label" for="CellPhone">Cell Phone</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="CellPhone" type="text" placeholder="Cell Phone" formControlName="CellPhone" maxlength="22"
							/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label" for="EmergencyName">Emergency Name</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="EmergencyName" type="text" placeholder="Emergency Name" formControlName="EmergencyName" maxlength="50"
							/>
						</div>

						<label class="col-md-2 control-label" for="EmergencyPhone">Emergency Phone</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="EmergencyPhone" type="text" placeholder="Emergency Phone" formControlName="EmergencyPhone"
							    maxlength="22" />
						</div>
						
					</div>
					<div class="form-group">
						<label class="col-md-2 control-label required" for="Password"><span class="text-danger">*</span>Password</label>
						<div class="col-md-4 form-group-item">
							<input class="form-control" id="Password" type="text" placeholder="Password" formControlName="Password" />
						</div>
						<label class="col-md-2 control-label" >Branch</label>
						<div class="col-md-4 form-group-item">
								<select formControlName="Branch" class="form-control" (change)="branchChange($event.target)">
                                        <option *ngFor="let item of branchs" [value]="(item.Id)">{{item.BranchName}}</option>
                                </select>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-12">
							<h4> User Roles</h4>
							<a class='btn btn-default btn-xs' (click)="toggleCreateSection()">
								<span class='glyphicon glyphicon-plus-sign' *ngIf="!showCreateSection"></span> {{ !showCreateSection ? 'Add New Role' : 'Cancel' }}
							</a>
							<button class='btn btn-primary btn-xs' type="button" *ngIf="showCreateSection" [disabled]='selectedRole === ""' (click)="addToRole()">
								<span class="glyphicon glyphicon-floppy-save"></span> Save
							</button>
						</div>
						<div class="col-md-12" *ngIf="showCreateSection" style="margin-top: 10px;">
							<select class="form-control" [formControl]='formControlRole' (change)="handleValue()">
								<option value=""></option>
								<option *ngFor='let role of roleNames;'
									[value]="role">
									{{role}} </option>
							</select>
						</div>
					</div>

					<table class="table">
						<thead>
							<tr>
								<th>Role Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody *ngIf="user.Roles && user.Roles.length">
							<tr *ngFor="let role of user.Roles">
								<td>{{role}}</td>
								<td>
									<a class="btn btn-default btn-xs" (click)="removeFromRole(role)">
										<span class="glyphicon glyphicon-remove"></span> Remove
									</a>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="col-md-12">
						<div class='form-group' >					
							<div class='col-md-10'>
								<div *ngIf="editUserForm.controls['Image'].value">
									<img [src]="urldatausers" style="width: 300px; height:160px;" >
								</div>
							</div>
						</div>
						<div class='form-group' *ngIf="user.UserId">
							<label class='col-md-2 control-label'> Users</label>
							<div class='col-md-10'>
								<input (change)="onFileSelected($event,'Users')" type="file" >
							</div>
						</div>
					</div>
					<div class="col-md-12 form-group-item">
						<div class='form-group' >					
							<div class='col-md-10'>
								<div *ngIf="editUserForm.controls['Signature'].value">
									<img [src]="urldatasignature" style="width: 300px; height:160px;" >
								</div>
							</div>
						</div>
						<div class='form-group' *ngIf="user.UserId">
							<label class='col-md-2 control-label'> Signature</label>
							<div class='col-md-10'>
								<input (change)="onFileSelected($event,'Signature')" type="file" >
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>


		<div class='row' style='padding: 15px;'>
			<div class='col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml'
						(click)='editUser(true)' style='width: 110px; margin-right:10px'
						[disabled]='!editUserForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml'
						(click)='editUser(false)' style='width: 150px; margin-right:10px'
						[disabled]='!editUserForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
				</span>

				<span class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml' (click)='cancel()'
						style='width: 150px; margin-right:10px' [disabled]='!editUserForm.valid'>
						Cancel &nbsp;
					</button>
				</span>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-body">
						<h4 class="modal-title">Change User Name</h4>
						<div class="row">
							<div class="col-md-2">
								<label>New User Name</label>
							</div>
							<div class="col-md-10">
								<input class="form-control" [(ngModel)]="newUserName" placeholder="User Name" />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" (click)="changeUserName()">
							Change
						</button>
						<button type="button" class="btn btn-primary" (click)="closeNewUserNamePopup()">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
