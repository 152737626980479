import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { WorkareaService } from '../workarea-service';
import { IWorkareaSearch } from '../workarea-model/workareasearch';
import { IWorkareas } from '../workarea-model/workareas';

@Component({
	templateUrl: './workarea-list.component.html',
	styleUrls: ['./workarea-list.component.css']
})

export class WorkareaListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'BranchId', 'Name', 'NickName', 'Color', 'PrinterType', 'IP', 'ZPL', 'PLCChannel', 'Channel', 'port', 'MacAddress', 'FileName', 'Email', 'SendAlert'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public BranchId = '';
	public Name = '';
	public NickName = '';
	public Color = '';
	public PrinterType = '';
	public IP = '';
	public ZPL = '';
	public PLCChannel = '';
	public Channel = '';
	public port = '';
	public MacAddress = '';
	public FileName = '';
	public Email = '';
	public SendAlert = '';

	public pageTitle = 'Work Areas List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: WorkareaService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {

			if(i.Item=='BranchId')
				this.BranchId = i.Value;

			if(i.Item=='Name')
				this.Name = i.Value;

			if(i.Item=='NickName')
				this.NickName = i.Value;

			if(i.Item=='Color')
				this.Color = i.Value;

			if(i.Item=='PrinterType')
				this.PrinterType = i.Value;

			if(i.Item=='IP')
				this.IP = i.Value;

			if(i.Item=='ZPL')
				this.ZPL = i.Value;

			if(i.Item=='PLCChannel')
				this.PLCChannel = i.Value;

			if(i.Item=='Channel')
				this.Channel = i.Value;

			if(i.Item=='port')
				this.port = i.Value;

			if(i.Item=='MacAddress')
				this.MacAddress = i.Value;

			if(i.Item=='FileName')
				this.FileName = i.Value;

			if(i.Item=='Email')
				this.Email = i.Value;

			if(i.Item=='SendAlert')
				this.SendAlert = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IWorkareaSearch = this._appService.getFreshSearch();

		search.BranchId = this.BranchId;
		search.Name = this.Name;
		search.NickName = this.NickName;
		search.Color = this.Color;
		search.PrinterType = this.PrinterType;
		search.IP = this.IP;
		search.ZPL = this.ZPL;
		search.PLCChannel = this.PLCChannel;
		search.Channel = this.Channel;
		search.port = this.port;
		search.MacAddress = this.MacAddress;
		search.FileName = this.FileName;
		search.Email = this.Email;
		search.SendAlert = this.SendAlert;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IWorkareas) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWorkareas){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.BranchId = '';
		this.Name = '';
		this.NickName = '';
		this.Color = '';
		this.PrinterType = '';
		this.IP = '';
		this.ZPL = '';
		this.PLCChannel = '';
		this.Channel = '';
		this.port = '';
		this.MacAddress = '';
		this.FileName = '';
		this.Email = '';
		this.SendAlert = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/workareas`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IWorkareaSearch = this._appService.getFreshSearch();

			search.BranchId = this.BranchId;
			search.Name = this.Name;
			search.NickName = this.NickName;
			search.Color = this.Color;
			search.PrinterType = this.PrinterType;
			search.IP = this.IP;
			search.ZPL = this.ZPL;
			search.PLCChannel = this.PLCChannel;
			search.Channel = this.Channel;
			search.port = this.port;
			search.MacAddress = this.MacAddress;
			search.FileName = this.FileName;
			search.Email = this.Email;
			search.SendAlert = this.SendAlert;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}