import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Machine16Component } from './machine-hmi-16/machine16.component';
import { PLCService } from './PLC-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-module'
import { LoggingService } from './logging-service';
import { ChartModule,ChartAllModule ,LineSeriesService,CategoryService,LegendService,DateTimeService ,DateTimeCategoryService} from '@syncfusion/ej2-angular-charts';
import { AreaSeriesService, RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService, 
      MultiColoredAreaSeriesService, StackingStepAreaSeriesService, SplineRangeAreaSeriesService } from '@syncfusion/ej2-angular-charts';

@NgModule({
  declarations: [
	 Machine16Component
  ],
  imports: [ 
	BrowserModule,  
	ChartAllModule,
	ChartModule,
    MatProgressSpinnerModule,
	MaterialModule,
	BrowserModule,
	FormsModule,
	ReactiveFormsModule,
	CommonModule,
	RouterModule.forChild([
		{ path: 'machine16', component: Machine16Component },
	]),
  ],
  providers: [
	AreaSeriesService , RangeAreaSeriesService, StepAreaSeriesService, StackingAreaSeriesService,
               DateTimeService, CategoryService, MultiColoredAreaSeriesService,StackingStepAreaSeriesService,SplineRangeAreaSeriesService
	,DateTimeCategoryService,
	DateTimeService,
	LegendService,
	LineSeriesService,
	CategoryService,
		PLCService,
		LoggingService
	]
})
export class MachineHMIModule { }
