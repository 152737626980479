import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators, FormControl  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IJob } from '../job-model/job';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { JobService } from '../job-service';
import { IJobs } from '../Job-model/Jobs';
import { IJobSearch } from '../Job-model/JobSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { PartService } from '../../parts/part-service';
import { IPart } from '../../parts/Part-model/Part';
import { MatTableDataSource } from '@angular/material/table';
import { EquipmentService } from '../../equipments/equipment-service';
import { IEquipment } from '../../equipments/equipment-model/equipment';
import { ICompany } from '../../companys/Company-model/Company';
import { CompanyService } from '../../companys/company-service';

@Component({
	templateUrl: './job-edit.component.html',
	styleUrls: ['job-edit.component.css']
})

export class JobEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	
	public jobSequenceDisplayedColumns: string[] = ['Id', 'EquipmentId', 'Sequence', 'IsScheduled', 'IsCompleted'];
	public jobSequenceRecordTotal: number = 0;

	public JobId = '';
	public ScheduleId = 0;
	public CompanyId = 0;

	public page = 'Edit Jobs';
	errorMessage: string;

	jobForm: FormGroup;
	job: IJob;

	public parts: IPart[];
	public jobs: IJob[];
	public equipments: IEquipment[];
	public companies: ICompany[];

	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public JobSearch: IJobSearch;
	public ListData: IJobs;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private jobService: JobService
		, private partService: PartService
		, private equipmentService: EquipmentService
		, private companyService: CompanyService
		) {
	}

	ngOnInit(): void {
		this.jobForm = this.fb.group({
			Id: '',
			ScheduleId: '',
			CompanyId: '',
			PartId: '',
			JobNumber: ['',[Validators.required, Validators.maxLength(255)]],
			Description: ['',[Validators.maxLength(1000)]],
			Quantity: ['', [Validators.min(1)]],
			DueDate: ['',[Validators.required]],
			IsCompleted: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.partService.getAllParts().subscribe((data) => {
			this.parts = data;
		});

		this.jobService.getAllJobs().subscribe((data) => {
			this.jobs = data;
		});

		this.equipmentService.getAllEquipments().subscribe((data) => {
			this.equipments = data;
		});

		this.companyService.getAllCompanies().subscribe((data)=>{
			this.companies=data;
		})

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getJob(id);
				this.JobId = id.toString();
				this.progressStatus = true;
			}
		);
	}

	ngAfterViewInit(): void {
	}

	public getSelectedRecordSet():void{
		this.jobService.getRecordSet(this.JobSearch)
			.subscribe((obj: IJobs) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	public getEquipmentById(id: number): string {
		if (this.equipments) {
			let equipment = this.equipments.find(x => x.Id == id);
			return equipment.Name;
		} else {
			return "";
		}
	}

	private onSelectedRecordSetReceived(obj: IJobs){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.job.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length)
			this.showNextRecordButton = true;
		else
			this.showNextRecordButton = false;

		this.progressStatus = false;

	}

	saveJob(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.jobForm.valid) {
			const obj = Object.assign({ }, this.job, this.jobForm.value);
			this.jobService.saveJob(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.jobForm.reset();
			this.router.navigate(['/jobs']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/job-details', 0]);
			this.jobForm.reset();
			let obj = this.jobService.initializeJob();
			this.onJobRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.job.Id === 0) {
			this.router.navigate(['/job-details', data.Id]);
		} 
		else if (this.job.Id != 0){
			this.getJob(this.job.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.job.Id)
				record = count;
		});
		this.router.navigate(['/job-details', this.ListData.data[record].Id]);
	}

	getJob(id: number): void {
		this.jobService.getJob(id)
			.subscribe((obj: IJob) => this.onJobRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onJobRetrieved(job: IJob): void {
		if (this.jobForm) {
			this.jobForm.reset();
		}
		this.job = job;
		if (this.job.Id === 0) {
			this.page = 'Add Jobs';
		} else {
			this.page = 'Edit Jobs';
		}
		
		this.jobForm.patchValue({
			Id: this.job.Id,
			ScheduleId: this.job.ScheduleId,
			CompanyId: this.job.CompanyId,
			PartId: this.job.PartId,
			JobNumber: this.job.JobNumber,
			Description: this.job.Description,
			Quantity: this.job.Quantity,
			DueDate: this.job.DueDate,
			IsCompleted: this.job.IsCompleted,
			CreatedDate: this.job.CreatedDate,
			CreatedBy: this.job.CreatedBy,
			UpdatedDate: this.job.UpdatedDate,
			UpdatedBy: this.job.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteJob(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.job.JobNumber}?`)) {
			this.jobService.deleteJob(this.job.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/jobs']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Jobs List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.JobSearch = this.jobService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'ScheduleId')
				this.JobSearch.ScheduleId = i.Value;
			if (i.Item == 'CompanyId')
				this.JobSearch.CompanyId = i.Value;

			if (i.Item == 'PartId')
				this.JobSearch.PartId = i.Value;

			if (i.Item == 'JobNumber')
				this.JobSearch.JobNumber = i.Value;

			if (i.Item == 'Description')
				this.JobSearch.Description = i.Value;

			if (i.Item == 'Quantity')
				this.JobSearch.Quantity = i.Value;

			if (i.Item == 'DueDate')
				this.JobSearch.DueDate = i.Value;

			if (i.Item == 'IsCompleted')
				this.JobSearch.IsCompleted = i.Value;

			if (i.Item == 'orderByString')
				this.JobSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.JobSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}

	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
