<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-lg-6 col-md-6 col-xs-12 options-bar'>
				{{pageTitle}}
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [routerLink]="['/lookuplist-details/0']"><mat-icon>add</mat-icon>Add LookUp List</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='showHideSearchClick()'><mat-icon *ngIf="showHideSearch">speaker_notes_off</mat-icon><mat-icon *ngIf="!showHideSearch">speaker_notes</mat-icon>{{SearchText}}
			</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='toExcel()'><mat-icon>cloud_download</mat-icon>To Excel</button>
			</div>
		</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Form</label>
				<input type='text' class='form-control block-element' placeholder=' Form' [(ngModel)]='Form' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Field</label>
				<input type='text' class='form-control block-element' placeholder=' Field' [(ngModel)]='Field' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Value</label>
				<input type='text' class='form-control block-element' placeholder=' Value' [(ngModel)]='Value' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button [routerLink]="['/lookuplist-details', e.Id]" mat-icon-button matTooltip="Edit this list"><mat-icon>edit</mat-icon></button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Form'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Form</th>
		<td mat-cell *matCellDef='let e'>{{e.Form}}</td>
	</ng-container>
	<ng-container matColumnDef='Field'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Field</th>
		<td mat-cell *matCellDef='let e'>{{e.Field}}</td>
	</ng-container>
	<ng-container matColumnDef='Value'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Value</th>
		<td mat-cell *matCellDef='let e'>{{e.Value}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>

