
<div class='panel panel-primary animaPlaceholder' *ngIf = 'equipment'>
	<div class='panel-heading'>
		<span *ngIf = 'equipment' >{{ page }} :{{ equipment.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='equipmentForm'>
			<fieldset>
			<div class='panel-body'>
				<div class="row">
					<div class="col-md-12">
						<div class="col-md-6">
							<div class='form-group'>
								<label class='col-md-2 control-label'> Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Name' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Nick Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='NickName' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Work Center ID</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='WorkCenterId' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Color</label>
								<div class='col-md-10'>
									<ejs-input ejs-colorpicker type="color" id="element" mode="Palette" formControlName="Color"></ejs-input>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Equipment Type</label>
								<div class='col-md-10'>
									<select class="form-control" formControlName='PrinterType'>
										<option value="2x4">2x4</option>
										<option value="4x6">4x6</option>
										<option value="HighPressure">HighPressure</option>
										<option value="LowPressure">LowPressure</option>
										<option value="Robot Cell">Robot Cell</option>
										<option value="Monitor PLC">Monitor PLC</option>
									</select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> IP</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='IP' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Branch </label>
								<div class='col-md-10'>
									<select class="form-control" formControlName='BranchId'>
										<option value=""> </option>
										<option *ngFor='let branch of branches;' [value]="branch.BranchNumber">
											{{branch.BranchName}} {{branch.BranchNumber}}</option>
									</select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> ZPL</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='ZPL' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> PLCChannel</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='PLCChannel' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Port</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='Port' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Mac Address</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='MacAddress' />
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class='form-group'>
								<label class='col-md-2 control-label'> Email Id</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Email' />
									<span *ngIf = "equipmentForm.controls['Email'].invalid  && equipmentForm.controls['Email'].errors.maxlength" class="text-danger">
										Please enter correct email.
								</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Send Alert</label>
								<div class='col-md-10'>
									<input class='form-control' style="width:34px;" type='checkbox' formControlName='SendAlert' />
								</div>
							</div>
							<div class='form-group' *ngIf="equipment.Id">					
								<div class='col-md-10'>
									<div *ngIf="equipmentForm.controls['FileName'].value">
										<img [src]="urldata" style="width: 500px; height:350px;" >
									</div>
								</div>
							</div>
							<div class='form-group' *ngIf="equipment.Id">
								<label class='col-md-2 control-label'> Image</label>
								<div class='col-md-10'>
									<input (change)="onFileSelected($event)" type="file" >
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary'
							[disabled]='buttonPushed'
								(click)='saveEquipment(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!equipmentForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveEquipment(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!equipmentForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && equipment.Id' class='btn btn-primary' 
									[disabled]='buttonPushed'
									(click)='saveEquipment(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!equipmentForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary' *ngIf="!equipment.Id"
									[disabled]='buttonPushed'
									(click)='saveEquipment(false, false,true)'
									style='width: 200px; margin-right:10px'
									[disabled]='!equipmentForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								</button>
							</span>

						<span  class='pull-right'>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!equipmentForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary' *ngIf="equipment.Id"
								[disabled]='buttonPushed'
								(click)='deleteEquipment()'
								style='width: 150px; margin-right:10px'
								[disabled]='!equipmentForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='equipment'>{{equipment.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='equipment'>{{equipment.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<app-loading *ngIf="progressStatus"></app-loading>