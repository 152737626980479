import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import {  DailylogComponent } from './dailylog.component';
import { DailylogService } from './dailylog-service';

@NgModule({
	declarations: [
		DailylogComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'dailylog', component: DailylogComponent }
		])
	],
	exports:[
		// WorklogListComponent
	],
	providers: [
		DailylogService
	]
})
export class DailyLogModule { }
