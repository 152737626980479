import { Component, Inject, OnInit } from "@angular/core"; 
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({selector: 'information-dialog',
templateUrl: './information-dialog.component.html'
 })   
export class InformationDialogComponent {

  constructor(
      public dialog: MatDialogRef<InformationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  

      closeDialog(): void {
        this.dialog.close(false);
      }
  }

  export interface DialogData {
    title: string;
    message: string;
  }