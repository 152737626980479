<div class='card animaPlaceholder' *ngIf='company'>
	<div class='card-heading fixed-bar'>
		<div class='row'>
			<div [class]="(company.Id==0 || !showNextRecordButton?'col-lg-4 ':'col-lg-2 ')+'col-md-11 col-xs-12'">
				<span *ngIf='company'>{{ page }} :{{ company.Id }}</span>
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveCompany(false, false,false)'
				[disabled]='!companyForm.valid || progressStatus'>
				<mat-icon>save</mat-icon>Save
				</button>
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveCompany(true, false,false)'
				[disabled]='!companyForm.valid || progressStatus'>
				<mat-icon>replay</mat-icon>Save & Return
				</button>
			</div>
			<div *ngIf='showNextRecordButton && company.Id' class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" 
				[disabled]='buttonPushed'
				(click)='saveCompany(false, true,false)'
				[disabled]='!companyForm.valid || progressStatus'>
				<mat-icon>forward</mat-icon>Save & Next
				</button>	
			</div>
			<div *ngIf="!company.Id" class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button  class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveCompany(false, false,true)'
				[disabled]='!companyForm.valid || progressStatus'>
				<mat-icon>forward</mat-icon>Save & Add New
				</button>	
			</div>
			<div *ngIf='company.Id!=0' class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed' (click)="showDialog('Company',0)"
				 [disabled]='!companyForm.valid || progressStatus'>
				 <mat-icon>delete_forever</mat-icon>Delete
			</button>
		</div>
		<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed' (click)='cancel()'
			 [disabled]='!companyForm.valid || progressStatus'>
			 <mat-icon>cancel</mat-icon>Cancel
		</button>
	</div>
	</div>
</div>

	<div class='card animaPlaceholder' *ngIf='company'>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<mat-tab-group>
			<mat-tab style="color: #101bb3;" label = "Company">
		<div class="row">
			<div class="col-md-6">
				<form class='form-horizontal' novalidate [formGroup]='companyForm'>
					<fieldset>
						<div class='card-body'>
							<div class="row">
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Company Name</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='CompanyName' />
									<span
										*ngIf="companyForm.controls['CompanyName'].invalid  && companyForm.controls['CompanyName'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Next Contact Date</label>
								<div class='col-md-12'>
									<input class='form-control' type='date' formControlName='NextContact' />
								</div>
							</div>
							<div class='form-group col-md-12 form-group-item'>
								<label class='col-md-4 control-label'> Address</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Address' />
									<span
										*ngIf="companyForm.controls['Address'].invalid  && companyForm.controls['Address'].errors.maxlength"
										class="text-danger">
										Only 4000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> City</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='City' />
									<span
										*ngIf="companyForm.controls['City'].invalid  && companyForm.controls['City'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Postal Code</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='PostalCode' />
									<span
										*ngIf="companyForm.controls['PostalCode'].invalid  && companyForm.controls['PostalCode'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Province</label>
								<div class='col-md-12 d-flex'>
									<button class="btn btn-primary btn-sm" (click)="openLookUpList('Companies','Province')">A</button>
									<select class="form-control" formControlName='Province'>
										<option *ngFor='let data of provinceList;' [value]="data.Value">
											{{data.Value}}</option>
									</select>
									<span
										*ngIf="companyForm.controls['Province'].invalid  && companyForm.controls['Province'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Country</label>
								<div class='col-md-12 d-flex'>
									<button class="btn btn-primary btn-sm" (click)="openLookUpList('Companies', 'Country')">A</button>
									<select class="form-control" formControlName='Country'>
										<option *ngFor='let data of countryList;' [value]="data.Value">
											{{data.Value}}</option>
									</select>
									<span
										*ngIf="companyForm.controls['Country'].invalid  && companyForm.controls['Country'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							
							<div class='form-group col-md-12 form-group-item'>
								<label class='col-md-4 control-label'> Notes</label>
								<div class='col-md-12'>
									<textarea class='form-control' type='text' formControlName='Notes'>
									</textarea>
									<span
										*ngIf="companyForm.controls['Notes'].invalid  && companyForm.controls['Notes'].errors.maxlength"
										class="text-danger">
										Only 4000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Website</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Website' />
									<span
										*ngIf="companyForm.controls['Website'].invalid  && companyForm.controls['Website'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Email</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Email' />
									<span
										*ngIf="companyForm.controls['Email'].invalid  && companyForm.controls['Email'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Phone</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Phone' />
									<span
										*ngIf="companyForm.controls['Phone'].invalid  && companyForm.controls['Phone'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> LinkedIn</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='LinkedIn' />
									<span
										*ngIf="companyForm.controls['LinkedIn'].invalid  && companyForm.controls['LinkedIn'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Instagram</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Instagram' />
									<span
										*ngIf="companyForm.controls['Instagram'].invalid  && companyForm.controls['Instagram'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Facebook</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Facebook' />
									<span
										*ngIf="companyForm.controls['Facebook'].invalid  && companyForm.controls['Facebook'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Status</label>
								<div class='col-md-12 d-flex'>
									<button class="btn btn-primary btn-sm" (click)="openLookUpList('Companies','Status')">A</button>
									<mat-select class="form-control" [formControlName]="'Status'">
										@for (item of statusList; track item) {
										  <mat-option [value]="item.Value">{{item.Value}}</mat-option>
										}
									</mat-select>
									<span
										*ngIf="companyForm.controls['Status'].invalid  && companyForm.controls['Status'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Priority</label>
								<div class='col-md-12 d-flex'>
									<button class="btn btn-primary btn-sm" (click)="openLookUpList('Companies','Priority')">A</button>
									<mat-select class="form-control" [formControlName]="'Priority'">
										@for (item of priorityList; track item) {
											<mat-option [value]="item.Value">{{item.Value==''?'(empty)':item.Value}}</mat-option>
										}
									</mat-select>
									<span
										*ngIf="companyForm.controls['Priority'].invalid  && companyForm.controls['Priority'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
						</div>
					</div>
					</fieldset>
				</form>
			</div>
			<div class="col-md-6" *ngIf="companyId">
				<customer-list-component [CompanyId]="CompanyId"></customer-list-component>
			</div>
		</div>



		<div *ngIf="companyId" class='card-body'>
		<form class='form-horizontal' novalidate [formGroup]='companyEngagementForm'>
		<div class='row'>
			<div class='col-lg-7 col-md-12 col-xs-12 options-bar'>
				Engagements details
			</div>
			<div class='col-lg-2 col-md-4 col-xs-4 options-bar'>
				<label class='col-md-6 control-label'> Contact Date</label>
				<div class='col-md-10'>
					<input class='form-control' type='date' formControlName='ContactDate' />
				</div>
				</div>

			<div class='col-lg-2 col-md-4 col-xs-4 options-bar'>
			<label class='col-md-6 control-label'> Type</label>
			<div class='col-md-10 d-flex'>
				<button class="btn btn-primary btn-sm" (click)="openLookUpList('CompanyEngagements','Type')">A</button>
				<mat-select class="form-control" [formControlName]="'Type'">
					@for (item of engagementTypesList; track item) {
						<mat-option [value]="item.Value">{{item.Value}}</mat-option>
					}
				</mat-select>
			</div>
			</div>
			<div class='col-lg-2 col-md-4 col-xs-4 options-bar'>
				<label class='col-md-6 control-label'> Contact Name</label>
				<div class='col-md-10'>
				<input class='form-control' type='text' formControlName='ContactName' />
				</div>
			</div>
			<div class='col-lg-4 col-md-4 col-xs-4 options-bar'>
				<label class='col-md-6 control-label'> Notes</label>
				<div class='col-md-10'>
				<input class='form-control' type='text' formControlName='Notes' />
				</div>
			</div>
			<div class='col-lg-2 col-md-6 col-xs-4 options-bar' style="padding-top: 30px;">
				<button class='btn btn-primary btn-sm center-block' style="width:100%;"  
				[disabled]='buttonPushed'(click)="SaveEngagement()"
			 	[disabled]='progressStatus'>Add</button>
			</div>
		</div>
	</form>
</div>

	<div *ngIf="companyId" class='table-container'>
		<table mat-table [dataSource]="dataSourceEngagements" class= 'mat-elevation-z8 table-wdt' matSort >
		 <ng-container matColumnDef="Edit">
		   <th mat-header-cell *matHeaderCellDef class='col1' >Edit</th>
		   <td mat-cell *matCellDef ="let element"> 
			<button (click)="UpdateEngagement(element)" mat-mini-fab color="default">
				<mat-icon>edit</mat-icon>
			</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="ContactDate" >
			<th mat-header-cell *matHeaderCellDef class='col1'> Contact Date </th>
			<td mat-cell *matCellDef="let element" > {{ element.ContactDate}} </td>
		   </ng-container>
		<ng-container matColumnDef="Type" >
		<th mat-header-cell *matHeaderCellDef class='col1'> Type </th>
		<td mat-cell *matCellDef="let element" > {{ element.Type}} </td>
	   </ng-container>
		<ng-container matColumnDef="ContactName" >
		<th mat-header-cell *matHeaderCellDef class= 'col1' > Contact Name </th>
		<td mat-cell *matCellDef = "let element" > {{ element.ContactName}} </td>
		</ng-container>
		<ng-container matColumnDef ="Notes">
		<th mat-header-cell *matHeaderCellDef class= 'col3' > Notes </th>
		<td mat-cell *matCellDef="let element" > {{ element.Notes}} </td>
		</ng-container>
		<ng-container matColumnDef="Delete" >
		<th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
		<td mat-cell *matCellDef ="let element" >
			<button (click)="showDialog('Engagement',element.Id)" mat-mini-fab color="warn">
				<mat-icon>delete</mat-icon>
			</button>
		</td>
		</ng-container>
	   <tr mat-header-row *matHeaderRowDef="displayedColumnsEngagements" ></tr>
	   <tr mat-row *matRowDef ="let row; columns: displayedColumnsEngagements;" ></tr>
	   </table>
	   <mat-paginator #firstPaginator [length]='recordTotalEngagements' [pageSize]='pageSize'></mat-paginator>
	  </div>


	</mat-tab>
	<mat-tab label="Files">
	<div *ngIf = "companyId" >
	 <div class='card-body'>
	<form [formGroup]='filesForm' novalidate
	style = "display: flex;flex-direction: column;row-gap: 10px; ">
	<div>
	<div class="col-md-2">
		<label class='control-label'>File Upload:</label>
	 </div>
	 <div class="col-md-4">
		<input class="form -control" type="file" #inputFile
	 (change)="onFileSelected($event)">
		<div *ngIf = "fileToEdit && fileToEdit.Id" >
		{{fileToEdit.FileName}}
	 </div>
	 <div *ngIf = "fileFormSubmitted && filesForm.controls.fileUpload.errors?.required" >
	 <span style="color:red"> Required </span></div>
	 </div>
	 </div>
	 <div>
	 <div class="col-md-2">
	 <label class=''> File Description </label>
	 </div>
	 <div class="col-md-4">
	 <input class="form-control" type ="text" formControlName = 'fileDescription' >
	 </div>
	 </div>
	 <div>
	 <div class="col-md-2">
	 <label class='control-label'> Sort Order </label>
	  </div>
	 <div class="col-md-4">
	 <input class="form-control" type = "number" formControlName = 'sortOrder' >
	 </div>
	 </div>
	 <div class= 'row mt-2' style ="display:flex;align-items: center;" >
	 <label class='col-md-2 control-label' > Include in Report </label>
	 <div class="col-md-1 text-left">
	  <input style = "width:34px;height:34px;" class= "form-check-input" type = "checkbox"
	 formControlName = 'includeReport' >
	 </div>
	 </div>
	 <div class="col-md-4 col-12 row mt-2">
	 <button class="btn btn-primary form-control center-center"
	 style ="width: auto;" type = "button"(click)="onSaveFile()" > Save
	 File </button>
	 </div>
	 </form>
	 </div>
	 <div>
		<div class='table-container'>
	  <table mat-table [dataSource] = "dataSource" class= 'mat-elevation-z8 table-wdt' matSort >
	   <ng-container matColumnDef="Edit">
		 <th mat-header-cell *matHeaderCellDef class='col1' > EDIT </th>
		 <td mat-cell *matCellDef ="let element"> <button class= 'btn btn-default'
		 style= 'min-width: 50px;' (click) ="editFileData(element)" > <span style="color: rgb(4, 50, 255);"
		 class="glyphicon glyphicon-edit"> EDIT </span ></button></td>
	  </ng-container>
	  <ng-container matColumnDef="FileName" >
	  <th mat-header-cell *matHeaderCellDef class='col2'> File Name </th>
	  <td mat-cell *matCellDef = "let element" >
		<a href ="javascript:void(0)"
		 (click) = "open(element)" > {{element.FileName}}
		</a>
	  </td>
	 </ng-container>
	  <ng-container matColumnDef="FileDescription" >
	  <th mat-header-cell *matHeaderCellDef class= 'col4' > Description </th>
	  <td mat-cell *matCellDef = "let element" > {{ element.FileDescription}} </td>
	  </ng-container>
	  <ng-container matColumnDef ="SortOrder">
	  <th mat-header-cell *matHeaderCellDef class= 'col5' > Sort Order </th>
	  <td mat-cell *matCellDef="let element" > {{ element.SortOrder}} </td>
	  </ng-container>
	   <ng-container matColumnDef="IncludeInReport">
	   <th mat-header-cell *matHeaderCellDef class= 'col6' > Include In Report</th>
	   <td mat-cell *matCellDef ="let element" > {{element.IncludeInReport === 1 ? 'True' :
	   'False'}}
	   </td>
	   </ng-container>
	  <ng-container matColumnDef="Delete" >
	  <th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
	  <td mat-cell *matCellDef ="let element" >
		<button (click)="showDialog('File',element.Id)" mat-mini-fab color="warn">
			<mat-icon>delete</mat-icon>
		</button>
	  </td>
	  </ng-container>
	 <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
	 <tr mat-row *matRowDef ="let row; columns: displayedColumns;" ></tr>
	 </table>
	 <mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
	</div>
	</div>
	 </div>
	 </mat-tab>
	 </mat-tab-group>
		<!-- <div class='row' style='padding: 15px;'>
			<div class='col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveCompany(false, false)' style='width: 110px; margin-right:10px'
						[disabled]='!companyForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveCompany(true, false)' style='width: 150px; margin-right:10px'
						[disabled]='!companyForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
					<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed'
						(click)='saveCompany(false, true)' style='width: 190px; margin-right:10px'
						[disabled]='!companyForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
					</button>
				</span>

				<span class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='cancel()'
						style='width: 150px; margin-right:10px' [disabled]='!companyForm.valid || progressStatus'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' [disabled]='buttonPushed' (click)='deleteCompany()'
						style='width: 150px; margin-right:10px' [disabled]='!companyForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div> -->

		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='company'>{{company.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='company'>{{company.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
</div>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>