
<div class='panel panel-primary animaPlaceholder' *ngIf = 'part'>
	<div class='panel-heading'>
		<span *ngIf = 'part' >{{ page }} : {{ part.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<mat-tab-group>
			<mat-tab label = "Part Details">

				<form class='form-horizontal' novalidate [formGroup]='partForm'>
					<fieldset>
						<div class="row">
						<div class="col-md-4">
						<div class='panel-body'>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Material</label>
								<div class='col-md-12'>
									<ng-select
										formControlName='MaterialId'
										placeholder="Select material...">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let material of materials" [value]="material.Id">
											{{material.Name}}
										</ng-option>
									</ng-select>
									<span *ngIf = "partForm.controls['MaterialId'].invalid  && partForm.controls['MaterialId'].errors.min" class="text-danger">
											Must select a material.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Work Area</label>
								<div class='col-md-12'>
									<ng-select
										formControlName='WorkAreaId'
										placeholder="Select work area...">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let workarea of workareas" [value]="workarea.Id">
											{{workarea.Name}}
										</ng-option>
									</ng-select>
									<span *ngIf = "partForm.controls['WorkAreaId'].invalid  && partForm.controls['WorkAreaId'].errors.min" class="text-danger">
											Must select a work area.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Part Number</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='PartNumber' />
									<span *ngIf = "partForm.controls['PartNumber'].invalid  && partForm.controls['PartNumber'].errors.required" class="text-danger">
										Must have part number.
									</span>
									<span *ngIf = "partForm.controls['PartNumber'].invalid  && partForm.controls['PartNumber'].errors.maxlength" class="text-danger">
										Only 255 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Description</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Description' />
									<span *ngIf = "partForm.controls['Description'].invalid  && partForm.controls['Description'].errors.maxlength" class="text-danger">
											Only 4000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<div class='row'>
								<div class='col-md-6'>
									<mat-checkbox formControlName='LightsOut' color='primary'>Lights Out</mat-checkbox>
								</div>
								<div class='col-md-6'>
									<mat-checkbox formControlName='Intervention' color='primary'>Intervention</mat-checkbox>
								</div>
							</div>
							</div>
							<div class='form-group'>
								<label class='col-md-4 control-label'> Intervention Quantity</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='InterventionQuantity' />
									<span *ngIf = "partForm.controls['InterventionQuantity'].invalid  && partForm.controls['InterventionQuantity'].errors.required" class="text-danger">
										Must have quantity.
									</span>
									<span *ngIf = "partForm.controls['InterventionQuantity'].invalid  && partForm.controls['InterventionQuantity'].errors.min" class="text-danger">
										Intervention quantity must be greater than 0.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-4 control-label'> Intervention Runtime</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='InterventionRunTime' />
									<span *ngIf = "partForm.controls['InterventionRunTime'].invalid  && partForm.controls['InterventionRunTime'].errors.required" class="text-danger">
										Must have Intervention run time.
									</span>
									<span *ngIf = "partForm.controls['InterventionRunTime'].invalid  && partForm.controls['InterventionRunTime'].errors.min" class="text-danger">
										Intervention run time must be greater than 0.
									</span>
								</div>
							</div>

							<div *ngIf="partFileMainImage" class='form-group'>
								<div class='col-md-12'>
									<img width="200px" src={{applicationSettings.retrieveUrl()+partFileMainImage.FilePath+partFileMainImage.GeneratedFileName}}  style="pointer-events: none;"/>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class='panel-body'>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Shape</label>
								<div class='col-md-12'>
									<mat-select class="form-control" [formControlName]="'PartShape'" (selectionChange)='shapeValueChanged($event.source.value)'>
										@for (item of ShapesList; track item) {
										  <mat-option [value]="item">{{item}}</mat-option>
										}
									</mat-select>
									<span *ngIf = "partForm.controls['PartShape'].invalid  && partForm.controls['PartShape'].errors.min" class="text-danger">
											Must select a Part Shape.
									</span>
								</div>
							</div>
							<div *ngIf="shapeSelected=='Round'||shapeSelected=='Round Shaft'" class='form-group'>
								<label class='col-md-4 control-label'> Radius</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Radius' />
									<span *ngIf = "partForm.controls['Radius'].invalid  && partForm.controls['Radius'].errors.required" class="text-danger">
										Must have Radius value
									</span>
									<span *ngIf = "partForm.controls['Radius'].invalid  && partForm.controls['Radius'].errors.min" class="text-danger">
										Radius must be greater than 0.
									</span>
								</div>
							</div>

							<div class='form-group'>
								<label class='col-md-4 control-label'> Length</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Length' />
									<span *ngIf = "partForm.controls['Length'].invalid  && partForm.controls['Length'].errors.required" class="text-danger">
										Must have Length value
									</span>
									<span *ngIf = "partForm.controls['Length'].invalid  && partForm.controls['Length'].errors.min" class="text-danger">
										Length must be greater than 0.
									</span>
								</div>
							</div>


							<div *ngIf="shapeSelected=='Square'||shapeSelected=='Rectangle'" class='form-group'>
								<label class='col-md-4 control-label'> Width</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Width' />
									<span *ngIf = "partForm.controls['Width'].invalid  && partForm.controls['Width'].errors.required" class="text-danger">
										Must have Width value
									</span>
									<span *ngIf = "partForm.controls['Width'].invalid  && partForm.controls['Width'].errors.min" class="text-danger">
										Width must be greater than 0.
									</span>
								</div>
							</div>

							<div *ngIf="shapeSelected=='Square'||shapeSelected=='Rectangle'" class='form-group'>
								<label class='col-md-4 control-label'> Height</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Height' />
									<span *ngIf = "partForm.controls['Height'].invalid  && partForm.controls['Height'].errors.required" class="text-danger">
										Must have Height value
									</span>
									<span *ngIf = "partForm.controls['Height'].invalid  && partForm.controls['Height'].errors.min" class="text-danger">
										Height must be greater than 0.
									</span>
								</div>
							</div>


							<div class='form-group'>
								<label class='col-md-4 control-label'> FirstPartX</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='FirstPartX' />
									<span *ngIf = "partForm.controls['FirstPartX'].invalid  && partForm.controls['FirstPartX'].errors.required" class="text-danger">
										Must have FirstPartX value
									</span>
									<span *ngIf = "partForm.controls['FirstPartX'].invalid  && partForm.controls['FirstPartX'].errors.min" class="text-danger">
										FirstPartX must be greater than 0.
									</span>
								</div>
							</div>


							<div class='form-group'>
								<label class='col-md-4 control-label'> FirstPartY</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='FirstPartY' />
									<span *ngIf = "partForm.controls['FirstPartY'].invalid  && partForm.controls['FirstPartY'].errors.required" class="text-danger">
										Must have FirstPartY value
									</span>
									<span *ngIf = "partForm.controls['FirstPartY'].invalid  && partForm.controls['FirstPartY'].errors.min" class="text-danger">
										FirstPartY must be greater than 0.
									</span>
								</div>
							</div>

							<div class='form-group'>
								<label class='col-md-4 control-label'> SpacingX</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='SpacingX' />
									<span *ngIf = "partForm.controls['SpacingX'].invalid  && partForm.controls['SpacingX'].errors.required" class="text-danger">
										Must have SpacingX value
									</span>
									<span *ngIf = "partForm.controls['SpacingX'].invalid  && partForm.controls['SpacingX'].errors.min" class="text-danger">
										SpacingX must be greater than 0.
									</span>
								</div>
							</div>

							<div class='form-group'>
								<label class='col-md-4 control-label'> SpacingY</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='SpacingY' />
									<span *ngIf = "partForm.controls['SpacingY'].invalid  && partForm.controls['SpacingY'].errors.required" class="text-danger">
										Must have SpacingY value
									</span>
									<span *ngIf = "partForm.controls['SpacingY'].invalid  && partForm.controls['SpacingY'].errors.min" class="text-danger">
										SpacingY must be greater than 0.
									</span>
								</div>
							</div>

							<div class='form-group'>
								<label class='col-md-4 control-label'> Rows</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Rows' />
									<span *ngIf = "partForm.controls['Rows'].invalid  && partForm.controls['Rows'].errors.required" class="text-danger">
										Must have Rows value
									</span>
									<span *ngIf = "partForm.controls['Rows'].invalid  && partForm.controls['Rows'].errors.min" class="text-danger">
										Rows must be greater than 0.
									</span>
								</div>
							</div>

							<div class='form-group'>
								<label class='col-md-4 control-label'> Columns</label>
								<div class='col-md-12'>
									<input class='form-control' type='number' formControlName='Columns' />
									<span *ngIf = "partForm.controls['Columns'].invalid  && partForm.controls['Columns'].errors.required" class="text-danger">
										Must have Columns value
									</span>
									<span *ngIf = "partForm.controls['Columns'].invalid  && partForm.controls['Columns'].errors.min" class="text-danger">
										Columns must be greater than 0.
									</span>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-4">
					<div class='panel-body'>
				<div class="row">
					<div class="col-md-12">
					<img *ngIf="shapeSelected=='Square'" src={{imgSquare}} style="width:100%;pointer-events: none;"/>
					<img *ngIf="shapeSelected=='Round'" src={{imgRound}}  style="width:100%;pointer-events: none;"/>
					<img *ngIf="shapeSelected=='Round Shaft'" src={{imgRoundShaft}}  style="width:100%;pointer-events: none;"/>
					<img *ngIf="shapeSelected=='Rectangle'" src={{imgRectangle}}  style="width:100%;pointer-events: none;"/>
				</div>
				<div class="col-md-12">
					<img src={{imgSpacingTemplate}} class="img-template"/>
				</div>
				</div>

				</div>
			</div>

				</div>
					</fieldset>
				</form>
			</mat-tab>
			<mat-tab label = "Files">
				<div *ngIf="part.Id" >
					<div class='card-body'>
				   <form [formGroup]='filesForm' novalidate
				   style = "display: flex;flex-direction: column;row-gap: 10px; ">
				   <div>
				   <div class="col-md-2">
					   <label class='control-label'>File Upload:</label>
					</div>
					<div class="col-md-4">
					   <input class="form -control" type="file" #inputFile
					(change)="onFileSelected($event)">
					   <div *ngIf = "fileToEdit && fileToEdit.Id" >
					   {{fileToEdit.FileName}}
					</div>
					<div *ngIf = "fileFormSubmitted && filesForm.controls.fileUpload.errors?.required" >
					<span style="color:red"> Required </span></div>
					</div>
					</div>
					<div>
					<div class="col-md-2">
					<label class=''> File Description </label>
					</div>
					<div class="col-md-4">
					<input class="form-control" type ="text" formControlName = 'fileDescription' >
					</div>
					</div>
					<div>
					<div class="col-md-2">
					<label class='control-label'> Sort Order </label>
					 </div>
					<div class="col-md-4">
					<input class="form-control" type = "number" formControlName = 'sortOrder' >
					</div>
					</div>
					<div class= 'row mt-2' style ="display:flex;align-items: center;" >
					<label class='col-md-2 control-label' > Include in Report </label>
					<div class="col-md-1 text-left">
					 <input style = "width:34px;height:34px;" class= "form-check-input" type = "checkbox"
					formControlName = 'includeReport' >
					</div>
					</div>
					<div class="col-md-2 col-12 row mt-2">
						<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)="onSaveFile()">Save and Upload File</button>
					</div>
					</form>
					</div>
					<br>
					<div>
					   <div class='table-container'>
					 <table mat-table [dataSource] = "dataSource" class= 'mat-elevation-z8 table-wdt' matSort >
					  <ng-container matColumnDef="Edit">
						<th mat-header-cell *matHeaderCellDef class='col1' > EDIT </th>
						<td mat-cell *matCellDef ="let element"> <button class= 'btn btn-default'
						style= 'min-width: 50px;' (click) ="editFileData(element)" > <span style="color: rgb(4, 50, 255);"
						class="glyphicon glyphicon-edit"> EDIT </span ></button></td>
					 </ng-container>
					 <ng-container matColumnDef="FileName" >
					 <th mat-header-cell *matHeaderCellDef class='col2'> File Name </th>
					 <td mat-cell *matCellDef = "let element" >
					   <a href ="javascript:void(0)"
						(click) = "open(element)" > {{element.FileName}}
					   </a>
					 </td>
					</ng-container>
					 <ng-container matColumnDef="FileDescription" >
					 <th mat-header-cell *matHeaderCellDef class= 'col4' > Description </th>
					 <td mat-cell *matCellDef = "let element" > {{ element.FileDescription}} </td>
					 </ng-container>
					 <ng-container matColumnDef ="SortOrder">
					 <th mat-header-cell *matHeaderCellDef class= 'col5' > Sort Order </th>
					 <td mat-cell *matCellDef="let element" > {{ element.SortOrder}} </td>
					 </ng-container>
					  <ng-container matColumnDef="IncludeInReport">
					  <th mat-header-cell *matHeaderCellDef class= 'col6' > Include In Report</th>
					  <td mat-cell *matCellDef ="let element" > {{element.IncludeInReport === 1 ? 'True' :
					  'False'}}
					  </td>
					  </ng-container>
					 <ng-container matColumnDef="Delete" >
					 <th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
					 <td mat-cell *matCellDef ="let element" >
						<button (click)="showDialog('File',element.Id)" mat-mini-fab color="warn">
							<mat-icon>delete</mat-icon>
						</button>
					 </td>
					 </ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
					<tr mat-row *matRowDef ="let row; columns: displayedColumns;" ></tr>
					</table>
					<mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
				   </div>
				   </div>
					</div>
		</mat-tab>
		</mat-tab-group>
			</div>
	
		<div class='row' style='padding: 15px;'>
			<div class= 'col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='savePart(false, false,false)'
						style='width: 110px; margin-right:10px'
						[disabled]='!partForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='savePart(true, false,false)'
						style='width: 150px; margin-right:10px'
						[disabled]='!partForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
					<button *ngIf='showNextRecordButton && part.Id ' class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='savePart(false, true,false)'
						style='width: 190px; margin-right:10px'
						[disabled]='!partForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
					</button>
					<button  class='btn btn-primary btn-edit-sml' *ngIf="!part.Id"  
							[disabled]='buttonPushed'
							(click)='savePart(false, false,true)'
							style ='width: 200px; margin-right:10px'
							[disabled]='!partForm.valid || progressStatus'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
						</button>
				</span>
				<span  class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='cancel()'
						style='width: 150px; margin-right:10px'
						[disabled]='!partForm.valid || progressStatus'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' *ngIf="part.Id" 
						[disabled]='buttonPushed'
						(click)='deletePart()'
						style='width: 150px; margin-right:10px'
						[disabled]='!partForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='part'>{{part.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='part'>{{part.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
<div>
	<app-loading *ngIf="progressStatus"></app-loading>
</div>