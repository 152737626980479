
<div class='card animaPlaceholder'>
	<div class='card-heading card-heading-custom'>
	<div class='row'>
		<div class='col-lg-7 col-md-12 col-xs-12 options-bar'>
			{{pageTitle}}
		</div>
		<div class='col-lg-3 col-md-3 col-xs-6 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary"
			[routerLink]="['/customer-details/0',inCompanyId]" 
			[disabled]="progressStatus"><mat-icon>add</mat-icon>Add Customer
		</button>
		</div>
		<div class='col-lg-3 col-md-3 col-xs-6 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary"
			(click)='showHideSearchClick()'
			[disabled]="progressStatus"><mat-icon>search</mat-icon>{{SearchText}}
		</button>
	</div>
		<div class='col-lg-3 col-md-3 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary"
			(click)='toExcel()' 
			[disabled]="progressStatus"><mat-icon>cloud_download</mat-icon>To Excel
		</button>

		</div>
		<div class='col-lg-3 col-md-3 col-xs-6 options-bar'>
				<mat-select class="form-control" [formControl]="pageSizeLst" (selectionChange)='pageSizeValueChanged($event.source.value)'>
					<mat-option *ngFor="let item of applicationSettings.pageSizes" [value]="item">
						<mat-option [value]="item">Show {{item}} rows</mat-option>
					  </mat-option>
				</mat-select>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-12'>
			<div class='row'>
			<div class='col-md-4  form-group-item'>
				<label class='inline-label'> Company Id</label>
				<input type='text' class='form-control block-element' placeholder=' Company Id' [(ngModel)]='CompanyId' />
			</div>
			<div class='col-md-4  form-group-item'>
				<label class='inline-label'> First Name</label>
				<input type='text' class='form-control block-element' placeholder=' First Name' [(ngModel)]='FirstName' />
			</div>
			<div class='col-md-4  form-group-item'>
				<label class='inline-label'> Last Name</label>
				<input type='text' class='form-control block-element' placeholder=' Last Name' [(ngModel)]='LastName' />
			</div>
			<div class='col-md-4  form-group-item'>
				<label class='inline-label'> Email</label>
				<input type='text' class='form-control block-element' placeholder=' Email' [(ngModel)]='Email' />
			</div>
			<div class='col-md-4  form-group-item'>
				<label class='inline-label'> Phone Number</label>
				<input type='text' class='form-control block-element' placeholder=' Phone Number' [(ngModel)]='PhoneNumber' />
			</div>
			</div>
		</div>
		<div class='col-md-12'>
			<div class='row'>
				<div class='col-md-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search'(click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button (click)="editCustomer(e.Id,inCompanyId)" mat-mini-fab color="default">
				<mat-icon>edit</mat-icon>
			</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='CompanyId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Company Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.CompanyId}}</td>
	</ng-container>
	<ng-container matColumnDef='Title'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Title</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.Title}}</td>
	</ng-container>
	<ng-container matColumnDef='FirstName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> First Name</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.FirstName}}</td>
	</ng-container>
	<ng-container matColumnDef='LastName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Last Name</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.LastName}}</td>
	</ng-container>
	<ng-container matColumnDef='Email'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Email</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.Email}}</td>
	</ng-container>
	<ng-container matColumnDef='PhoneNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Phone Number</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.PhoneNumber}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>