import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IWorkarea} from './Workarea-model/Workarea';
import { IWorkareas } from './Workarea-model/Workareas';
import { IWorkareaSearch } from './Workarea-model/WorkareaSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class WorkareaService {
	private baseUrl;
	public pageTitle = 'Work Areas List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Workareas';
	}

	getWorkarea(id: number):  Observable<IWorkarea> {
		if (id === 0) {
			return of(this.initializeWorkarea());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IWorkarea>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveWorkarea(workarea: IWorkarea): Observable<any> {
		if (workarea.Id === 0) {
			return this.createWorkarea(workarea);
		}
		return this.updateWorkarea(workarea);
	}

	private createWorkarea(workarea: IWorkarea): Observable<any> {
		workarea.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/workareacreate', workarea)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateWorkarea(workarea: IWorkarea): Observable<IWorkarea> {
		return this.authHttp.put<IWorkarea>(this.baseUrl, workarea)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteWorkarea(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	public getWorkareasByBranch(branchId): Observable<IWorkarea[]> {
		return this.authHttp.get<IWorkarea[]>(this.baseUrl + '/bybranch?branchId=' + branchId)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeWorkarea(): IWorkarea {
		return {
			Id: 0,
			BranchId: 0,
			Name: '',
			NickName: '',
			Color: '',
			PrinterType: '',
			IP: '',
			ZPL: '',
			PLCChannel: 0,
			Channel: 0,
			port: 0,
			MacAddress: '',
			FileName: '',
			Email: '',
			SendAlert: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IWorkarea;
	}

	public getRecordSet(search: IWorkareaSearch): Observable<IWorkareas> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('NickName', search.NickName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Color', search.Color, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PrinterType', search.PrinterType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('IP', search.IP, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ZPL', search.ZPL, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PLCChannel', search.PLCChannel, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Channel', search.Channel, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('port', search.port, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('MacAddress', search.MacAddress, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('FileName', search.FileName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Email', search.Email, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SendAlert', search.SendAlert, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('NickName', search.NickName ? search.NickName : '')
			.set('Color', search.Color ? search.Color : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
			.set('Channel', search.Channel ? search.Channel : '')
			.set('port', search.port ? search.port : '')
			.set('MacAddress', search.MacAddress ? search.MacAddress : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Email', search.Email ? search.Email : '')
			.set('SendAlert', search.SendAlert ? search.SendAlert : 'false')
		};

		return this.authHttp.get<IWorkareas>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IWorkareas>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IWorkareaSearch { 
		let search: IWorkareaSearch = {
			Id: 1,
			BranchId: '',
			Name: '',
			NickName: '',
			Color: '',
			UnlimitedJobs: '',
			LightsOut: '',
			Ignore: '',
			PrinterType: '',
			IP: '',
			ZPL: '',
			PLCChannel: '',
			Channel: '',
			port: '',
			MacAddress: '',
			FileName: '',
			Email: '',
			SendAlert: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	getAllWorkareas():  Observable<IWorkarea[]> {
		return this.authHttp.get<IWorkarea[]>(this.baseUrl)
			.pipe(retry(1),
			catchError(this.processError));
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IWorkareaSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('NickName', search.NickName ? search.NickName : '')
			.set('Color', search.Color ? search.Color : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
			.set('Channel', search.Channel ? search.Channel : '')
			.set('port', search.port ? search.port : '')
			.set('MacAddress', search.MacAddress ? search.MacAddress : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Email', search.Email ? search.Email : '')
			.set('SendAlert', search.SendAlert ? search.SendAlert : 'false')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}