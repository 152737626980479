import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { WorkareaListComponent } from './workarea-list/workarea-list.component';
import { WorkareaEditComponent } from './workarea-edit/workarea-edit.component';
import { WorkareaService } from './workarea-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [
		WorkareaListComponent
		, WorkareaEditComponent
	],
	imports: [
		ColorPickerModule,
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		NgSelectModule,
		MatCheckboxModule,
		RouterModule.forChild([
			{ path: 'workareas', component: WorkareaListComponent },
			{ path: 'workarea-details/:Id', component: WorkareaEditComponent },
		])
	],
	providers: [
		WorkareaService
	]
})
export class WorkareaModule { }
