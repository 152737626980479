import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { EquipmentEditComponent } from './equipment-edit/equipment-edit.component';
import { EquipmentHelpComponent } from './equipment-help/equipment-help.component';
import { EquipmentService } from './equipment-service';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';

@NgModule({
	declarations: [
		EquipmentListComponent
		, EquipmentEditComponent
		, EquipmentHelpComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		ColorPickerModule,
		RouterModule.forChild([
			{ path: 'equipments', component: EquipmentListComponent },
			{ path: 'equipment-details/:Id', component: EquipmentEditComponent },
			{ path: 'equipment-help', component: EquipmentHelpComponent },
		]),
	],
	providers: [
		EquipmentService
	]
})
export class EquipmentModule { }
