import { Component } from "@angular/core"; 
import { HeaderVersionService } from "./header-version.service";
@Component({selector: 'header-version',
templateUrl: './header-version.component.html',     
styleUrls: ['./header-version.component.css']   })   
export class HeaderVersionComponent   {     
public angularDateVersion ='03 Jun 2024 06:37';
public webApiVersion = '';
errorMessage: string;
constructor(private apiversionService: HeaderVersionService,
) {
}

getAPIVersion() {
  this.apiversionService.getByLink().subscribe(resp => {
    console.log(resp);
    this.webApiVersion = resp;
  }, (error: any) => this.errorMessage = <any>error);

} 

ngOnInit(): void {
  this.getAPIVersion();
}

}

