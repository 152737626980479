import {
	Component,
	OnInit,
	AfterViewInit,
	OnDestroy,
	ViewChildren,
	ElementRef
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	FormArray,
	Validators,
	FormControlName
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericValidator } from '../../../shared/generic-validator';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IRole } from '../role-model/role';
import { RoleService } from '../role-service/role.service';

@Component({
	templateUrl: './role-edit.component.html'
})
export class RoleEditComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

	editRoleForm!: FormGroup;
	pageTitle = 'Edit Role';
	errorMessage = '';
	role!: IRole;
	private sub!: Subscription;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator!: GenericValidator;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private roleService: RoleService
	) {
		this.validationMessages = {
			Name: {
				required: 'Name is required.'
			}
		};
	}

	ngOnInit(): void {
		this.editRoleForm = this.fb.group({
			Name: ['', [Validators.required]]
		});

		this.genericValidator = new GenericValidator(this.validationMessages);

		this.sub = this.route.params.subscribe(params => {
			const id = params['Id'];
			this.getRole(id);
		});
	}

	ngAfterViewInit(): void {
		// // Watch for the blur event from any input element on the form.
		// const controlBlurs: Observable<any>[] = this.formInputElements.map(
		// 	(formControl: ElementRef) =>
		// 		Observable.fromEvent(formControl.nativeElement, 'blur')
		// );

		// // Merge the blur event observable with the valueChanges observable
		// Observable.merge(this.editRoleForm.valueChanges, ...controlBlurs)
		// 	.debounceTime(800)
		// 	.subscribe(value => {
		// 		this.displayMessage = this.genericValidator.processMessages(
		// 			this.editRoleForm
		// 		);
		// 	});
	}

	cancel(): void {
		this.router.navigate(['/roles']);
	}

	editRole(): void {
		if (this.editRoleForm.dirty && this.editRoleForm.valid) {
			const roleEdited = Object.assign(
				{},
				this.role,
				this.editRoleForm.value
			);

			this.roleService
				.updateRole(roleEdited)
				.subscribe(
					() => this.onSaveComplete(),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else if (!this.editRoleForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		this.editRoleForm.reset();
		this.router.navigate(['/roles']);
	}

	getRole(id: string): void {
		this.roleService
			.getRole(id)
			.subscribe(
				(role: IRole) => this.onRoleRetrieved(role),
				(error: any) => (this.errorMessage = <any>error)
			);
	}

	onRoleRetrieved(role: IRole): void {
		this.role = role;
		// Update the data on the form
		this.editRoleForm.patchValue({
			Name: this.role.Name
		});
	}
}
