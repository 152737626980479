
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-lg-6 col-md-6 col-xs-12 options-bar'>
				{{pageTitle}}
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [routerLink]="['/audit-details/0']"><mat-icon>add</mat-icon>Add Audit</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='showHideSearchClick()'><mat-icon *ngIf="showHideSearch">speaker_notes_off</mat-icon><mat-icon *ngIf="!showHideSearch">speaker_notes</mat-icon>{{SearchText}}
			</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='toExcel()'><mat-icon>cloud_download</mat-icon>To Excel</button>
			</div>
		</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Table Changed</label>
				<input type='text' class='form-control block-element' placeholder=' Table Changed' [(ngModel)]='TableChanged' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Column Changed</label>
				<input type='text' class='form-control block-element' placeholder=' Column Changed' [(ngModel)]='ColumnChanged' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Key Id</label>
				<input type='text' class='form-control block-element' placeholder=' Key Id' [(ngModel)]='KeyId' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> What Happened</label>
				<input type='text' class='form-control block-element' placeholder=' What Happened' [(ngModel)]='WhatHappened' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Old Value</label>
				<input type='text' class='form-control block-element' placeholder=' Old Value' [(ngModel)]='OldValue' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> New Value</label>
				<input type='text' class='form-control block-element' placeholder=' New Value' [(ngModel)]='NewValue' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button [routerLink]="['/audit-details', e.Id]" mat-icon-button matTooltip="Edit this audit's detail"><mat-icon>edit</mat-icon></button>
		</td>
	</ng-container>
	<ng-container matColumnDef='TableChanged'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Table Changed</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.TableChanged}}</td>
	</ng-container>
	<ng-container matColumnDef='ColumnChanged'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Column Changed</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.ColumnChanged}}</td>
	</ng-container>
	<ng-container matColumnDef='KeyId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Key Id</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.KeyId}}</td>
	</ng-container>
	<ng-container matColumnDef='WhatHappened'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> What Happened</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.WhatHappened}}</td>
	</ng-container>
	<ng-container matColumnDef='OldValue'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Old Value</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.OldValue}}</td>
	</ng-container>
	<ng-container matColumnDef='NewValue'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> New Value</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.NewValue}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>